import { api } from './ApiInstanceInjector'

export const BrandFilesService = {

  getAll (brandId) {
    return api.get(`brands/${brandId}/files`, { params: { sort: 'newest' } })
      .then(response => response.data.data)
  },

  upload (file, brandId) {
    const formData = new FormData()

    formData.append('name', file.name)
    formData.append('brand_id', brandId)
    formData.append('file', file)

    return api.post(`brands/${brandId}/files`, formData)
      .then(response => response.data.data)
  },

  update (brandId, fileId, payload) {
    return api.put(`brands/${brandId}/files/${fileId}`, payload)
      .then(response => response.data.data)
  },

  delete (brandId, fileId) {
    return api.delete(`brands/${brandId}/files/${fileId}`)
  }
}
