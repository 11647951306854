<template>
  <div class="StoryEditCoverRow columns is-gapless is-marginless">
    <div class="column is-3 is-flex">
      <div class="StoryEditCoverRow__Description">
        <div class="is-expanded p-a-s">
          <div
            v-if="newsroom"
            class="is-flex is-aligned-center m-b-m"
          >
            <round-avatar
              :src="newsroom.logo.cropped"
              :acronym-fallback="newsroom.name"
              :main-color="newsroom.main_color"
              size="x-large"
            />
          </div>
          <form-field
            :validator="validator.newsroom_id"
            :label="$t('pages.story_edit.choose_newsroom')"
            required
            name="newsroom"
          >
            <basic-select
              v-model="form.newsroom_id"
              :values="newsrooms"
              key-prop="id"
              value-prop="id"
              label-prop="name"
              class="NewsroomPicker is-transparent"
              popper-class="NewsroomPickerModal"
            >
              <template slot-scope="{ item }">
                <country-flag :country="item.country.iso_alpha2" />
                {{ item.name }}
              </template>
            </basic-select>
          </form-field>
          <div
            v-if="isPublished"
            class="has-text-centered"
          >
            <a
              :href="storyUrl"
              class="button is-plain is-dark m-t-m p-l-none"
              target="hn_view_story"
            >
              <span class="icon">
                <v-icon type="eye" />
              </span>
              <span>
                {{ $t('forms.view_on_hypenews') }}
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div
      ref="croppieWrapperWrapper"
      class="column is-9 is-position-relative"
    >
      <croppie-wrapper
        ref="cover"
        v-model="form.cover"
        :scaled-sizes="scaledSizes"
        :settings="croppieSettings"
        :crop-options="croppieSettings.cropOptions"
        :no-image-sizes="{ height: noImageHeight, width: fullWidth }"
        text-size="6"
        entity="StoriesCover"
      />
      <!-- StoryEditCoverCaption -->
      <div class="StoryEditCoverRow__caption p-l-s">
        <editable-field-extended
          v-model="form.cover_caption"
          :placeholder="$t('pages.story_edit.cover_image_caption')"
          :empty-text="$t('pages.story_edit.cover_image_caption')"
          auto-stop-edit
          floating-label
          inline-placeholder
          class="m-t-s has-text-grey"
          data-testid="CoverCaption"
          @cancel="form.cover_caption = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { round } from '@hypefactors/shared/js/utils/numberUtils'
import { debounceMixin } from '@hypefactors/shared/js/mixins/debounceMixin'
import EditableFieldExtended from '@/components/forms/EditableFieldExtended'
import { Newsroom } from '@hypefactors/shared/js/models/Newsroom'

const COVER_WIDTH = 450
const COVER_HEIGHT = 253
const NO_IMAGE_COVER_HEIGHT = 153
const COVER_RATIO = round(COVER_WIDTH / COVER_HEIGHT)
const NO_IMAGE_COVER_RATIO = round(COVER_WIDTH / NO_IMAGE_COVER_HEIGHT)
/**
 * @module StoryEditCoverRow
 */
export default {
  name: 'StoryEditCoverRow',
  components: { EditableFieldExtended },
  mixins: [debounceMixin(['resize']), 100],

  props: {
    /** @type StoryForm */
    form: {
      type: Object,
      required: true
    },
    validator: {
      type: Object,
      required: true
    },
    isPublished: {
      type: Boolean,
      default: false
    },
    storyUrl: {
      type: String,
      required: true
    },
    /** @type Newsroom - Current applied newsroom to story */
    newsroom: {
      type: Newsroom,
      default: null
    },
    newsrooms: {
      type: Array,
      default: () => ({})
    }
  },

  data () {
    return {
      // Cover ratio to calculate size
      fullWidth: COVER_WIDTH,
      fullHeight: COVER_HEIGHT,
      noImageHeight: NO_IMAGE_COVER_HEIGHT,

      scaledSizes: {
        small: 0.8,
        medium: 1.5,
        large: 2
      }
    }
  },

  computed: {
    /**
     * Recalculates the croppieSettings based on the croppie container width
     * @returns {{viewport: {width: number, height: number}, boundary: {width: number, height: number}}}
     */
    croppieSettings () {
      return {
        viewport: { width: this.fullWidth, height: this.fullHeight },
        boundary: { width: this.fullWidth, height: this.fullHeight }
      }
    }
  },

  mounted () {
    this.resize()
    window.addEventListener('resize', this.resize)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.resize)
  },

  methods: {
    /**
     * Resizes the CroppieWrapper's size to keep its aspect ratio
     * @returns {Promise<void>}
     */
    async resize () {
      /* If the element is not available, bail out */
      if (!this.$refs.croppieWrapperWrapper) return

      this.fullWidth = this.$refs.croppieWrapperWrapper.clientWidth
      this.fullHeight = round(this.fullWidth / COVER_RATIO)
      this.noImageHeight = round(this.fullWidth / NO_IMAGE_COVER_RATIO)

      await this.$nextTick()
      if (this.$refs.cover) {
        this.$refs.cover.bind()
      }
    },

    /**
     * Creates a proxy to the {@see module:CroppieWrapper#methods.uploadImage}
     * @returns {Promise}
     */
    uploadImage () {
      return this.$refs.cover.uploadImage()
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.StoryEditCoverRow {
  margin-bottom: $margin !important;

  &__Description {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
  }

  .CroppieWrapper {
    display: block;

    .CroppieWrapper__overlay .CroppieWrapper__noImage {
      background: transparent;
      border-color: $hf__color-gray;
      border-width: 1px;

      &--drag {
        border-color: $hf__color-primary;
        background: $hf__color-white;
      }
    }
  }
}
</style>
