<script>
import EditableField from '@hypefactors/shared/js/components/forms/controls/EditableField'

const editableControlStoryType = () => import('@/components/stories/StoryEdit/EditableControlStoryType')
const EditableControlFreeFormDatePicker = () => import('@/components/stories/StoryEdit/EditableControlFreeFormDatePicker')
/**
 * Extends the {@see module:EditableField} to register control components
 * @module EditableFieldExtended
 */
export default {
  name: 'EditableFieldExtended',
  components: {
    'EditableControlStoryType': editableControlStoryType,
    'EditableControlFreeFormDatePicker': EditableControlFreeFormDatePicker
  },
  extends: EditableField
}
</script>
