<template>
  <div class="BrandFilesManager">
    <file-uploader :upload="uploadFile" />

    <file-manager
      ref="fileManager"
      v-loading="fetchingFiles"
      :value="value"
      :files="filesToLoop"
      :delete-action="deleteFile"
      :update-action="updateFile"
      @input="$emit('input', $event)"
      @update:files="refreshFiles"
    />
  </div>
</template>

<script>
import { BrandFilesService } from '@hypefactors/shared/js/services/api/BrandFilesService'
import FileManager from './files-manager/FileManager'

/**
 * CRUD List of brand files.
 * @module BrandFilesManager
 */
export default {
  components: { FileManager },

  props: {
    value: {
      type: Array,
      default: () => ([])
    },
    brandId: {
      type: String,
      required: true
    },
    autoSelectOnUpload: {
      type: Boolean,
      default: true
    },
    externalFiles: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
      brandFiles: [],
      fetchingFiles: false
    }
  },

  computed: {
    /**
     * Whether an external list of files is provided
     * ie. StoryEditV2 passes a list from Vuex
     * @return {boolean}
     */
    hasExternalFiles () {
      return !!this.$props.externalFiles
    },

    /**
     * Determines which files to loop over
     * Externally provided ones or the internal list
     * @return {Array}
     */
    filesToLoop () {
      return this.hasExternalFiles ? this.externalFiles : this.brandFiles
    }
  },

  created () {
    this.refreshFiles(true)
  },

  methods: {
    async deleteFile (file) {
      await BrandFilesService.delete(this.brandId, file.id)
      await this.refreshFiles()
    },

    async uploadFile (file) {
      const uploadedFile = await BrandFilesService.upload(file, this.brandId)

      await this.refreshFiles()

      if (this.autoSelectOnUpload) {
        this.$refs.fileManager.toggleFile(uploadedFile)
      }
    },

    async updateFile (file, payload) {
      try {
        await BrandFilesService.update(this.brandId, file.id, payload)
        await this.refreshFiles()
      } catch (err) {
        this.$displayRequestError(err)
      }
    },

    /**
     * Refreshes the files list after adding/removing files
     * If external files are provided emits a refresh event,
     * else fetches files locally
     * @emits refresh
     * @param {boolean} initial
     * @return {Promise<void>}
     */
    async refreshFiles (initial = false) {
      if (this.hasExternalFiles) {
        return !initial && this.$emit('refresh')
      }
      this.fetchingFiles = true
      this.brandFiles = await BrandFilesService.getAll(this.brandId)
      this.fetchingFiles = false
    }
  }
}
</script>
