<template>
  <div class="contact-card__data-row">
    <span v-show="value">{{ value }}</span>
    <span v-show="!value && placeholder" class="placeholder">{{ placeholder }}</span>
  </div>
</template>
<script>
export default {
  props: {
    value: { type: String, default: '' },
    placeholder: { type: String, default: '' }
  }
}
</script>
