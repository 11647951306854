<template>
  <div class="StoryEditStatus" data-testid="StoryEditStatus">
    {{ $t('general.status') }}:
    <el-tooltip
      :disabled="!isScheduledFor"
    >
      <span
        :class="{ 'StoryEditStatusText--scheduled': isScheduledFor }"
        class="StoryEditStatusText has-text-weight-bold"
      >
        {{ statusText }}
      </span>
      <template slot="content">
        <div>
          {{ $t('components.story_edit_status.scheduled_tooltip') }}
        </div>
        <div>
          {{ datetime(isScheduledFor) }}
        </div>
      </template>
    </el-tooltip>
  </div>
</template>

<script>
import DatetimeMixin from '@hypefactors/shared/js/mixins/DatetimeMixin'

/**
 * Generates a story status indicator
 * @module StoryEditStatus
 */
export default {
  name: 'StoryEditStatus',

  mixins: [DatetimeMixin],

  props: {
    /**
     * @type HF_Story
     */
    story: {
      type: Object,
      required: true
    }
  },
  computed: {

    /**
     * Returns the date when the story is scheduled to be published
     * @returns {String}
     */
    isScheduledFor () {
      return this.story.publishAt
    },

    /**
     * Returns the status text of the story
     * It can be new, scheduled, published or unpublished
     * @returns {string}
     */
    statusText () {
      if (!this.story.id) {
        return this.$t('components.story_edit_status.new_story')
      }

      if (this.isScheduledFor) {
        return this.$t('components.story_edit_status.scheduled')
      }

      return this.story.isPublished
        ? this.$t('general.published')
        : this.$t('general.unpublished')
    }
  }
}
</script>

<style lang="scss">
.StoryEditStatusText {
  &--scheduled {
    border-bottom: 1px dotted;
    cursor: help;
  }
}
</style>
