import _merge from 'lodash/merge'
import _isEmpty from 'lodash/isEmpty'
import Form from '@/services/forms/Form'
import { required } from 'vuelidate/lib/validators'
import { EMPTY_STYLES_FACTORY } from '@hypefactors/shared/js/factories/pr_story'

/**
 * @interface StoryPRStylesOverridesFormObject
 * @property {?string} pr_style_id
 * @property {HF_StoryPRStyles} overrides
 */

/**
 * @interface StoryPRStylesOverridesForm
 * @extends Form
 * @extends StoryPRStylesOverridesFormObject
 */

/**
 * Creates a new Press Release Styles Template and Overrides Form
 * Used to define the overrides and base style template in PR mode
 * @see module:StoryEditV2
 * @return StoryPRStylesOverridesForm
 */
export function createStoryPRStylesOverridesForm () {
  return new Form({
    pr_style_id: { value: null, rules: { required } },
    overrides: {
      value: EMPTY_STYLES_FACTORY()
    }
  })
}

/**
 * Prepares the press release style override object from the api response
 * @param {HF_StoryPRStyles} PRStyle
 * @return {StoryPRStylesOverridesFormObject}
 */
export function prepareStoryPRStylesOverridesObject (PRStyle) {
  return {
    pr_style_id: PRStyle.pr_style_id,
    overrides: _merge(EMPTY_STYLES_FACTORY(), PRStyle.overrides)
  }
}

/**
 * Receives the PR Style overrides and returns a cleaned up object for the api to save.
 * Removes fields that dont have a value
 * @param {StoryPRStylesOverridesFormObject} payload
 * @return {Object}
 */
export function createStoryPRStylesRequestObject (payload) {
  const overrides = Object.entries(payload.overrides)
    .filter(([key, value]) => {
      return !_isEmpty(value)
    })
    .reduce((all, [key, value]) => {
      all[key] = value
      return all
    }, {})
  return {
    ...payload,
    overrides
  }
}
