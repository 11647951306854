<template>
  <div
    class="ContactListCardPlaceholder"
    data-testid="ContactListCardPlaceholder"
  >
    <contact-list-item
      :contact="fakeContact"
    />
    <v-icon
      type="plus"
      size="3x"
      class="is-absolute-centered"
    />
  </div>
</template>

<script>
import ContactListItem from '@/components/stories/ContactListItem'

/**
 * @module ContactListCardPlaceholder
 */
export default {
  name: 'ContactListCardPlaceholder',
  components: { ContactListItem },
  data () {
    return {
      /** @type HF_Contact */
      fakeContact: {
        id: '0',
        name: 'John Doe',
        job_description: 'CEO',
        email: 'john@doe.com',
        brand_id: '0',
        phone: '0888 888 888',
        image: {
          cropped: this.$asset('/images/general/avatar_placeholder.png')
        }
      }
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.ContactListCardPlaceholder {
  position: relative;
  width: 100%;

  &:after {
    content: '';
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: .7;
    background: $grey-lighter;
  }
}
</style>
