<template>
  <div
    v-loading="isSaving || isFetchingStory"
  >
    <!-- StoryEditNavbar -->
    <story-edit-admin-bar
      :form="form"
      :story="story"
      :is-saving="isSaving"
      :is-publishing="isPublishing"
      :pr-mode.sync="form.inPRMode"
      :pr-styles-form="prStylesForm"
      @save="saveStory"
      @delete="goToStoryList"
      @publish="publishStory"
      @unpublish="unpublishStory"
    />
    <!-- StoryEdit Content -->
    <div
      :class="{ PressRelease: form.inPRMode }"
      class="StoryEditV2 StoryFull"
    >
      <section class="m-t-m">
        <transition name="fade">
          <story-edit-cover-row
            v-if="!form.inPRMode"
            ref="storyCover"
            :form="form"
            :validator="$v.form"
            :story-url="storyUrl"
            :newsroom="newsroom"
            :newsrooms="newsrooms"
            :is-published="story.isPublished"
          />
        </transition>
        <transition-group
          class="columns"
          name="list"
        >
          <div
            key="main-content"
            :class="{ 'is-9': !form.inPRMode, 'is-12': form.inPRMode }"
            class="column StoryFull__content"
          >
            <form-wrapper :validator="$v.form">
              <div
                :key="form.inPRMode"
                class="has-background-white p-a-s m-b-xl p-t-l"
              >
                <!-- prDate -->
                <editable-field-extended
                  v-if="form.inPRMode"
                  v-model="form.prDate"
                  :placeholder="$t('pages.story_edit.pr_date_placeholder')"
                  :empty-text="$t('pages.story_edit.pr_date_placeholder')"
                  :label="$t('pages.story_edit.pr_date')"
                  name="prDate"
                  inline-placeholder
                  floating-label
                  auto-stop-edit
                  data-testid="prDate"
                  class="StoryEditV2__prDateWrapper m-b-m"
                  control="EditableControlFreeFormDatePicker"
                  tabindex="0"
                  @cancel="form.prDate = $event"
                />
                <!-- Heading -->
                <editable-field-extended
                  :validator="$v.form.headline"
                  :value="form.headline"
                  :placeholder="$t('pages.story_edit.headline_placeholder')"
                  :empty-text="$t('pages.story_edit.headline_empty_placeholder')"
                  :label="$t('pages.story_edit.headline')"
                  :class="getCustomStyleClassesFor('headline')"
                  :show-pencil="false"
                  name="headline"
                  auto-stop-edit
                  floating-label
                  data-testid="Headline"
                  class="StoryEditV2__headlineWrapper border-b m-b-l"
                  control-class="StoryFull__headline"
                  control="EditableControlTextarea"
                  tabindex="0"
                  @input="form.headline = removeNewLineFromText($event)"
                  @cancel="form.headline = $event"
                >
                  {{ form.headline }}
                  <story-edit-pr-custom-style-bar
                    v-if="form.inPRMode"
                    slot="post-control-field"
                    v-model="prStylesForm.overrides.headline"
                    :merged-styles="mergedStyle.headline"
                  />
                </editable-field-extended>
                <!-- Subheading -->
                <editable-field-extended
                  :validator="$v.form.subheadline"
                  :value="form.subheadline"
                  :placeholder="$t('pages.story_edit.subheadline_placeholder')"
                  :empty-text="$t('pages.story_edit.subheadline_empty_placeholder')"
                  :label="$t('pages.story_edit.subheadline')"
                  :class="getCustomStyleClassesFor('subheadline')"
                  :show-pencil="false"
                  add-min-height
                  name="subheadline"
                  auto-stop-edit
                  floating-label
                  inline-placeholder
                  control="EditableControlTextarea"
                  control-class="StoryFull__subheadline StoryHeadline"
                  class="StoryEditV2__subheadlineWrapper StoryFull__subheadline border-b m-b-l"
                  tabindex="0"
                  data-testid="SubHeadline"
                  @input="form.subheadline = removeNewLineFromText($event)"
                  @cancel="form.subheadline = $event"
                >
                  <story-edit-pr-custom-style-bar
                    v-if="form.inPRMode"
                    slot="post-control-field"
                    v-model="prStylesForm.overrides.subheadline"
                    :merged-styles="mergedStyle.subheadline"
                  />
                </editable-field-extended>
                <!-- Copy Text -->
                <div class="StoryEditCopytext m-v-l border-b p-b-s">
                  <form-field
                    key="copytext"
                    name="copytext"
                  >
                    <froala-wrapper
                      v-model="form.copytext"
                      slot-scope="{validator}"
                      :config="froalaConfigs.copytext"
                      :show-editor.sync="froalaEditorsVisibility.copytext"
                      :floating-label="$t('pages.story_edit.copytext')"
                      :class="getCustomStyleClassesFor('copytext')"
                      is-immersive
                      name="copytext"
                      froala-id="copytext"
                      class="content"
                      required
                      tabindex="0"
                      data-testid="CopytextField"
                      @blur="validator.$touch()"
                      @hook:created="registerFroalaButtons"
                    />
                  </form-field>
                </div>
                <!-- Boilerplate -->
                <div class="StoryEditBoilerplate m-v-l border-b p-b-s">
                  <form-field
                    key="boilerplate"
                    name="boilerplate"
                  >
                    <froala-wrapper
                      key="boilerplate"
                      v-model="form.boilerplate"
                      slot-scope="{validator}"
                      :config="froalaConfigs.boilerplate"
                      :show-editor.sync="froalaEditorsVisibility.boilerplate"
                      :floating-label="$t('pages.story_edit.boilerplate')"
                      :class="getCustomStyleClassesFor('boilerplate')"
                      :empty-text-placeholder="$t('pages.story_edit.boilerplate_empty_placeholder')"
                      name="boilerplate"
                      froala-id="boilerplate"
                      is-immersive
                      required
                      tabindex="0"
                      data-testid="BoilerplateField"
                      @blur="validator.$touch()"
                    />
                  </form-field>
                </div>
                <!-- Contact -->
                <div class="StoryEditContact m-v-l border-b p-b-s">
                  <form-field
                    key="contact"
                    name="contact"
                  >
                    <froala-wrapper
                      key="contact"
                      v-model="form.contact"
                      slot-scope="{validator}"
                      :config="froalaConfigs.contact"
                      :show-editor.sync="froalaEditorsVisibility.contact"
                      :floating-label="$t('pages.story_edit.contact_label')"
                      :class="getCustomStyleClassesFor('contact')"
                      :empty-text-placeholder="$t('pages.story_edit.contact_empty_placeholder')"
                      name="contact"
                      froala-id="contact"
                      is-immersive
                      required
                      tabindex="0"
                      data-testid="ContactField"
                      @blur="validator.$touch()"
                    />
                  </form-field>
                </div>
              </div>

              <template v-if="!form.inPRMode">
                <!-- Contacts -->
                <div class="title-wrap">
                  <heading
                    size="5"
                    weight="semibold"
                    :serif="false"
                    gapless
                  >
                    {{ $t('pages.story_edit.contacts') }} /
                  </heading>
                  <div class="subtitle is-5">
                    {{ $t('pages.story_edit.for_more_info') }}
                  </div>
                </div>

                <story-edit-contacts v-model="form.contacts" />
              </template>
              <!-- Attachments -->
              <v-separator darker />
              <h4 class="title is-4 has-text-weight-semibold">
                {{ $t('pages.story_edit.attachments') }}
              </h4>
              <brand-files-manager
                v-model="form.files"
                :brand-id="activeBrandId"
                :url="`brands/${activeBrandId}/files`"
                :external-files="brandFiles"
                @refresh="fetchBrandFiles"
              />
            </form-wrapper>
          </div>
          <div
            v-if="!form.inPRMode"
            key="sidebar"
            class="column is-3"
          >
            <div class="StoryFull__sidebar">
              <!-- Story Type -->
              <div class="StoryTypePickerField control m-b-l">
                <heading
                  size="6"
                  weight="bold"
                  :serif="false"
                  class="m-b-none"
                >
                  {{ $t('pages.story_edit.story_type_placeholder') }}
                </heading>
                <el-select
                  v-model="form.type"
                  :placeholder="$t('pages.story_edit.story_type_placeholder')"
                  clearable
                  default-first-option
                  class="is-transparent EditableControlStoryType"
                  popper-class="StoryEdit__StoryTypesModal"
                >
                  <el-option
                    v-for="item in storyTypes"
                    :key="item"
                    :label="$t('components.story_types.' + item)"
                    :value="item"
                  />
                </el-select>
              </div>

              <story-edit-categories
                v-model="form.categories"
                class="m-b-l"
              />
              <story-edit-tags
                v-model="form.tags"
                class="m-b-l"
              />
              <heading
                size="6"
                weight="bold"
                :serif="false"
                class="m-b-none"
              >
                {{ $t('general.language') }}
              </heading>
              <form-field
                :validator="$v.form.language"
                name="language"
              >
                <language-picker
                  v-model="form.language"
                  class="StoryLanguagePicker is-transparent"
                />
              </form-field>
            </div>
          </div>
        </transition-group>
      </section>
    </div>
    <story-edit-validation-error-dialog
      :visible.sync="validationErrorDialog"
      :validator="$v.form"
    />
    <story-edit-send-to-journalist-dialog />
    <story-edit-pr-preview-dialog />
    <story-edit-share-on-social-dialog
      :story="story"
      :visible.sync="isShareOnSocialModalVisible"
    />
  </div>
</template>

<script>
import {
  createStoryPRStylesOverridesForm,
  createStoryPRStylesRequestObject,
  prepareStoryPRStylesOverridesObject
} from '@/forms/StoryPRStylesOverridesForm'
import { UPDATE_PR_STYLE_TEMPLATE_ENDPOINT } from '@/services/api/PressReleaseService'

import _merge from 'lodash/merge'
import { PREditorService } from '@/services/PREditorService'
import { mapActions, mapState, mapGetters } from 'vuex'
import { PRPreviewDialogMethods } from '@/store/modules/newsroom/helpers'

import { StoryApiService } from '@hypefactors/shared/js/services/api/StoryApiService'

import StoryEditTags from '@/components/stories/StoryEdit/StoryEditTags'
import EditableFieldExtended from '@/components/forms/EditableFieldExtended'
import StoryEditContacts from '@/components/stories/StoryEdit/StoryEditContacts'
import StoryEditAdminBar from '@/components/stories/StoryEdit/StoryEditAdminBar'
import StoryEditCoverRow from '@/components/stories/StoryEdit/StoryEditCoverRow'
import StoryEditCategories from '@/components/stories/StoryEdit/StoryEditCategories'
import BrandFilesManager from '@hypefactors/shared/js/components/forms/BrandFilesManager'
import StoryEditPrPreviewDialog from '@/components/stories/StoryEdit/StoryEditPrPreviewDialog'
import StoryEditPrCustomStyleBar from '@/components/stories/StoryEdit/StoryEditPrCustomStyleBar'
import StoryEditSendToJournalistDialog from '@/components/stories/StoryEdit/StoryEditSendToJournalistDialog'
import StoryEditValidationErrorDialog from '@/components/stories/StoryEdit/StoryEditValidationErrorDialog'

import { StoryForm } from '@/forms/StoryForm'
import { Story } from '@hypefactors/shared/js/models/Story'

import StoryEditShareOnSocialDialog from '@/components/stories/StoryEdit/StoryEditShareOnSocialDialog'
import { dateToUnixTimestamp } from '@hypefactors/shared/js/utils'
import { STORY_TYPES_ARRAY } from '@/constants/story'

/**
 * Page responsible for Creating and Editing Stories.
 * @module StoryEditV2
 */
export default {
  name: 'StoryEditV2',

  components: {
    StoryEditShareOnSocialDialog,
    StoryEditPrCustomStyleBar,
    StoryEditPrPreviewDialog,
    StoryEditSendToJournalistDialog,
    StoryEditValidationErrorDialog,
    EditableFieldExtended,
    StoryEditAdminBar,
    BrandFilesManager,
    StoryEditTags,
    StoryEditCoverRow,
    StoryEditCategories,
    StoryEditContacts
  },

  props: {
    storyId: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      isFetchingStory: false,
      isPublishing: false,
      isSaving: false,
      isShareOnSocialModalVisible: false,
      storyIncludes: ['categories', 'tags', 'contacts', 'language', 'files', 'pr_styles', 'brand', 'newsroom'],
      /** @type HF_Story */
      story: new Story(),
      /** @type StoryForm */
      form: new StoryForm(),
      /** @type StoryPRStylesOverridesForm */
      prStylesForm: createStoryPRStylesOverridesForm(),

      froalaEditorsVisibility: {
        copytext: false,
        boilerplate: false,
        contact: false
      },

      validationErrorDialog: false
    }
  },

  validations () {
    return {
      form: this.form.rules(),
      prStylesForm: this.prStylesForm.rules()
    }
  },

  computed: {
    ...mapGetters(['currentUser', 'languages']),
    ...mapState('newsroom', ['brandFiles', 'brandPRStyleTemplates']),
    ...mapGetters('newsroom', ['findNewsroom', 'newsrooms']),

    storyTypes () {
      return STORY_TYPES_ARRAY
    },

    isNewStory () {
      return !this.storyId
    },

    storyUrl () {
      if (!this.newsroom) return ''
      return this.$getUrl(`${this.newsroom.slug}/${this.story.slug}`, 'hypenews', false)
    },

    /**
     * Finds the current applied PR style from the ones on the Brand
     * @return {HF_BrandPRStyleTemplate}
     */
    currentAppliedPRStyleTemplate () {
      return this.brandPRStyleTemplates.find(template => template.id === this.prStylesForm.pr_style_id)
    },

    /**
     * Returns the Custom PR Styles, merged between the applied template styles and story style overrides from the form.
     * @returns HF_StoryPRStyles
     */
    mergedStyle () {
      return _merge({},
        this.currentAppliedPRStyleTemplate
          ? this.currentAppliedPRStyleTemplate.styles
          : {},
        this.prStylesForm.overrides)
    },

    froalaConfigs () {
      return {
        copytext: {
          placeholderText: this.$t('pages.story_edit.copytext_empty_placeholder'),
          pluginsEnabled: ['quickInsert', 'paragraphFormat', 'link', 'lists', 'colors', 'table', 'image'],
          toolbarButtons: ['bold', 'italic', 'paragraphFormat', 'insertLink', 'formatOL', 'formatUL', 'insertTable', 'insertImage'].concat(this.form.inPRMode ? ['HF_PR_fontSize', 'HF_PR_fontFamily', 'color'] : []),
          paragraphFormat: {
            N: 'Normal',
            H3: 'Heading'
          },
          paragraphFormatSelection: true,
          linkAlwaysBlank: true,
          linkAlwaysNoFollow: false,
          linkEditButtons: ['linkEdit', 'linkRemove'],
          linkInsertButtons: [],
          linkList: [],
          toolbarBottom: true,
          heightMin: 300
        },
        boilerplate: {
          placeholderText: this.$t('pages.story_edit.boilerplate_placeholder'),
          toolbarBottom: true,
          toolbarButtons: ['bold'].concat(this.form.inPRMode ? ['HF_PR_fontSize', 'HF_PR_fontFamily'] : [])
        },
        contact: {
          toolbarBottom: true,
          placeholderText: this.$t('pages.story_edit.contact_placeholder'),
          toolbarButtons: ['bold'].concat(this.form.inPRMode ? ['HF_PR_fontSize', 'HF_PR_fontFamily'] : [])
        }
      }
    },

    newsroom () {
      if (!this.form.newsroom_id) return null
      return this.findNewsroom(this.form.newsroom_id, 'id')
    }
  },

  async beforeRouteLeave (to, from, next) {
    if (to.params.__forcePush) return next()
    if (!this.form.hasChanges() && !this.prStylesForm.hasChanges()) return next()
    try {
      await this.$confirm(this.$t('pages.story_edit.confirm_story_edit_changes'))
      next()
    } catch (err) {}
  },

  watch: {
    activeBrandId: 'handleBrandChange'
  },

  async mounted () {
    if (this.$route.query.newsroomId) {
      this.form.newsroom_id = this.$route.query.newsroomId
    } else if (this.newsrooms.length === 1) {
      this.form.newsroom_id = this.newsrooms[0].id
    }
    if (this.isNewStory) {
      // allow the PRmode to be set via query parameter.
      this.form.inPRMode = this.$route.query.inPRMode === 'true'
      this.prefillStoryWithData()
      return
    }
    // we can safely assume the current brandId is the story one
    this.fetchStory(this.storyId)
  },

  beforeDestroy () {
    this.SET_PR_PREVIEW_DIALOG_VISIBILITY(false)
  },

  methods: {
    ...mapActions('newsroom', ['fetchBrandFiles', 'setPrPreviewData']),
    ...mapActions(['setActiveBrand']),
    ...PRPreviewDialogMethods,

    fetchStory (storyId) {
      if (!storyId) return
      this.isFetchingStory = true
      return StoryApiService.fetchStory(storyId, {
        params: {
          include: this.storyIncludes
        }
      })
        .then(async (storyResponse) => {
          this.story = new Story(storyResponse)
          this.form.mergeModel(this.story)
          await this.$nextTick()
          // hack to stop the form from thinking it has changes.
          // Froala updates the inputs slightly on init.
          this.form.updateRevision()
          // always merge it, because we dont know when a story might change to PR mode.
          this.prStylesForm.merge(prepareStoryPRStylesOverridesObject(this.story.prStyles))
          this.setPrPreviewData({
            storyId,
            storyUpdatedAt: this.story.updatedAt
          })
        })
        .catch((err) => {
          this.$displayRequestError(err)
          if (err.response && err.response.status === 404) {
            this.goToStoryList()
          }
        })
        .finally(() => {
          this.isFetchingStory = false
        })
    },

    prefillStoryWithData () {
      this.form.prefilStory(this.newsroom, this.currentUser)
      this.$notify.success(this.$t('pages.story_edit.story_prefilled'))
    },

    /**
     * Saves the Story.
     * Calls Update or Create methods respectively
     * @returns {Promise<* | void>}
     */
    async saveStory () {
      this.$v.form.$touch()
      if (this.isSaving) return

      if (this.$v.form.$error) {
        this.validationErrorDialog = true
        return
      }
      const action = this.isNewStory ? this.__createStory : this.__updateStory
      this.form.prepareRequest(this.activeBrandId)
      const params = {
        params: { include: this.storyIncludes }
      }

      try {
        this.isSaving = true // force loading due to the Croppie uploadImage process
        if (!this.form.inPRMode) {
          await this.$refs.storyCover.uploadImage()
        }
        const response = await action(params)
        await this.handleUpdate(response)
      } catch (err) {
        this.$displayRequestError(err)
        // re-throw error so publish doesnt stop
        throw err
      } finally {
        this.isSaving = false
      }
    },

    /**
     * Calls the create story endpoint
     * @param params
     * @returns {Promise}
     * @private
     */
    async __createStory (params) {
      const isLangSupported = val => !!this.languages.find(l => l.code === val)

      if (!this.form.language) {
        // use the user's language
        let language = this.$TranslationService.currentLanguageNoLocale
        if (!isLangSupported(language)) {
          language = 'en'
        }
        try {
          // apply the language only if we have it in our database
          const languageCode = await StoryApiService.detectLanguage(this.form.copytext)
          if (isLangSupported(languageCode)) {
            language = languageCode
          }
        } catch (e) { }

        // assign the language
        this.form.language = language
      }
      return this.form.submit(payload => StoryApiService.createStory(payload, params))
    },

    /**
     * Calls the update story endpoint
     * @param params
     * @returns {Promise}
     * @private
     */
    __updateStory (params) {
      return this.form.submit((payload) => StoryApiService.updateStory(this.story.id, payload, params))
    },

    /**
     * Handles updates and creation of stories
     * @param {Object} story - returned story from update/create request
     * @emits StoryEditV2:save
     */
    async handleUpdate (story) {
      const storyId = story.id
      this.story = new Story(story)
      try {
        // if we are in PR mode and the styles have changes, update them
        if (this.form.inPRMode && this.prStylesForm.hasChanges()) {
          this.prStylesForm.setPayloadTransformer(createStoryPRStylesRequestObject)
          await this.prStylesForm.post(UPDATE_PR_STYLE_TEMPLATE_ENDPOINT(storyId))
          // re-fetch the story so we have the new template assigned and merged into it.
          if (!this.isNewStory) {
            await this.fetchStory(storyId)
          }
        }
      } catch (err) {
        this.$displayRequestError(err, this.$t('errors.cannot_save_pr_styles'))
      }

      this.$bus.$emit('StoryEditV2:save')

      if (this.isNewStory) {
        this.$router.push({ name: 'story.edit', params: { storyId: storyId } }, () => {
          this.fetchStory(this.storyId)
          this.$notify.success(this.$t('success.story_created_message'))
        })
        return
      }
      this.$notify.success(this.$t('success.story_updated_message'))
    },

    /**
     * Publishes a story
     * Saves the story if not saved
     * Sets the isPublished to true on the Story model
     * @returns {Promise<void>}
     */
    async publishStory () {
      if (!this.form.id) return
      try {
        this.isPublishing = true
        if (this.form.hasChanges()) {
          try {
            await this.saveStory()
          } catch (err) {
            return
          }
        }
        const response = await StoryApiService.publishStory(this.form.id, dateToUnixTimestamp(this.form.publishAt))
        this._updateAfterPublishStatusChange(response)
        this.$notify.success(this.form.publishAt ? this.$t('success.story_scheduled') : this.$t('success.story_published'))
      } catch (err) {
        this.$displayRequestError(err)
      } finally {
        this.isPublishing = false
      }
    },

    /**
     * Unpublishes a story
     * Saves the story if not saved
     * Sets the isPublished to False on the Story model
     * @returns {Promise<void>}
     */
    async unpublishStory () {
      if (!this.form.id) return
      let message = (!this.form.publishAt && this.form.hasPropertyChanged('publishAt'))
        ? this.$t('success.story_unscheduled')
        : this.$t('success.story_unpublished')

      try {
        this.isPublishing = true
        if (this.form.hasChanges()) {
          try {
            await this.saveStory()
          } catch (err) {
            return
          }
        }
        const response = await StoryApiService.unpublishStory(this.form.id)
        this._updateAfterPublishStatusChange(response)
        this.$notify.success(message)
      } catch (err) {
        this.$displayRequestError(err)
      } finally {
        this.isPublishing = false
      }
    },

    /**
     * Called after story publish or unpublish has been triggered.
     * Used to set the publish related properties on the Story and the Form
     * @param {object} response - The story returned from publish endpoints
     * @private
     */
    _updateAfterPublishStatusChange (response) {
      this.story = new Story(response)
      if (this.story.firstPublishedAt && this.story.publishedAt) {
        this.isShareOnSocialModalVisible = this.story.firstPublishedAt === this.story.publishedAt
      }
      this.form.merge({
        publishedAt: response.published_at
      })
    },

    /**
     * Handles brand change while creating/editing a story
     */
    async handleBrandChange (newBrand, oldBrand) {
      if (this.isNewStory) {
        this.form.newsroom_id = null
        this.prefillStoryWithData()
        return
      }

      if (this.story.brand.id === newBrand) return

      try {
        await this.$confirm(this.$t('pages.story_edit.confirm_active_brand_change'))
        this.goToStoryList()
      } catch (err) {
        // revert old brand
        this.setActiveBrand(oldBrand)
      }
    },

    /**
     * Removes new lines from a string.
     * Used to remove the new lines from Headline and Subheadline textareas
     * @param {string} value
     * @returns {string}
     */
    removeNewLineFromText (value = '') {
      return value.replace(/\r?\n|\r/g, ' ').replace(/\s{2,}/g, ' ').trim()
    },

    /**
     * Goes to the Story List page
     * @param {Boolean} force
     */
    goToStoryList (force = true) {
      this.$router.push({
        name: 'story.list',
        params: {
          __forcePush: force
        }
      })
    },

    /**
     * Sets the custom style value for a Property on a field
     * @param {string} field - Content field which field is edited
     * @param {string} styleProperty - Custom style to change
     * @param {string} value - The value of the style that is being changed
     */
    setCustomStyleFor (field, styleProperty, value) {
      // Get the style object for the field
      const styleForId = this.prStylesForm.overrides[field] || {}
      this.$set(this.prStylesForm.overrides, field, Object.assign({}, styleForId, {
        // set the value
        [styleProperty]: value
      }))
    },

    /**
     * Gets the value for the property on the field
     * @param {string} field
     * @param {string} property
     * @return {string}
     */
    getCustomStyleValueFor (field, property) {
      return this.mergedStyle[field][property]
    },

    /**
     * Gets the applied classes for the current field
     * @param {string} field
     * @return {Object}
     */
    getCustomStyleClassesFor (field) {
      if (!this.form.inPRMode) return {}
      let fontSize = this.mergedStyle[field].fontSize
      let fontFamily = this.mergedStyle[field].fontFamily
      return {
        [`has-text-${fontSize}`]: fontSize,
        [`has-font-${fontFamily}`]: fontFamily
      }
    },
    registerFroalaButtons () {
      PREditorService.registerFroalaPRFontSizeButton(
        (field, value) => this.setCustomStyleFor(field, 'fontSize', value),
        (field) => this.getCustomStyleValueFor(field, 'fontSize'))
      PREditorService.registerFroalaPRFontFamilyButton(
        (field, value) => this.setCustomStyleFor(field, 'fontFamily', value),
        (field) => this.getCustomStyleValueFor(field, 'fontFamily'))
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.StoryEditV2 {
  &__headlineWrapper {
    textarea, .EditableField__preview, .EditableField__placeholder {
      font-weight: 600;
      font-size: $size-1;
      color: $hf__heading-color;

      .PressRelease & {
        font-size: 1em !important;
        font-weight: 600 !important;
        font-family: inherit;
      }
    }

    // override because it was messing with custom styles
    .control {
      font-size: inherit;
    }

    .PressRelease & {
      .EditableField__preview h1 {
        font-weight: 600;
      }
    }
  }

  &__subheadlineWrapper {
    textarea, .EditableField__preview, .EditableControl {
      font-weight: 900;
      font-size: 15px;
      color: $grey;
      // for applying custom styles
      .PressRelease & {
        font-family: inherit;
        font-size: 1em;
      }
    }

    // override as it was messing with custom styles
    .control {
      font-size: inherit;
    }
  }

  .StoryEditCopytext {
    .FroalaWrapper {
      min-height: 300px;
    }
  }

  &__prDateWrapper {
    line-height: 26px;
    height: 26px;

    .EditableControlFreeFormDatePicker__DateInput {
      font-size: 15px;
    }
  }
}
</style>
