<template>
  <div class="StoryEditCategories">
    <heading
      size="6"
      weight="bold"
      :serif="false"
      class="m-b-none"
    >
      {{ $t('general.categories') }}
    </heading>

    <story-categories-list
      v-if="storyCategories.length"
      :categories="storyCategories"
    />

    <v-button
      class="is-black is-full-width"
      data-testid="StoryEditCategoriesTrigger"
      @click="startEditing"
    >
      {{ $t('pages.newsroom_edit.set_categories') }}
    </v-button>

    <el-dialog
      :visible.sync="isEditing"
      :title="$t('components.story_newsroom_edit.newsroom_edit_categories.title')"
      data-testid="StoryEditCategoriesDialog"
      class="is-fullscreen-mobile"
      @close="stopEditing"
    >
      <div class="m-b-l is-size-6">
        {{ $t('components.story_newsroom_edit.newsroom_edit_categories.body') }}
      </div>

      <categories-picker
        v-model="storyCategories"
        :categories="categories"
        :categories-flat="categoriesFlat"
      />
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CategoriesPicker from '@hypefactors/shared/js/components/forms/CategoriesPicker'
import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'
import StoryCategoriesList from '@hypefactors/shared/js/components/story/StoryCategoriesList'

/**
 * Adds a dialog for Editing a story's categories
 * @module StoryEditCategories
 */
export default {
  name: 'StoryEditCategories',
  components: { StoryCategoriesList, CategoriesPicker },
  props: {
    value: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      isEditing: false
    }
  },

  computed: {
    ...mapGetters('newsroom', ['categories', 'categoriesFlat']),
    storyCategories: generateComputedSetterWithEmit()
  },

  methods: {
    /**
     * Opens the edit categories dialog
     */
    startEditing () {
      this.isEditing = true
    },
    /**
     * Closes the Edit Categories dialog
     */
    stopEditing () {
      this.isEditing = false
    }
  }
}
</script>
