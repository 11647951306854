<template>
  <div class="StoryEditScheduledPublishing p-a-m has-background-white is-inline-block">
    <div
      v-if="isPublished"
      data-testid="StoryPublishedContainer"
    >
      <heading
        class="is-marginless is-gapless m-b-s"
        size="5"
      >
        {{ $t('components.story_edit_scheduled_publishing.your_story_published') }}
      </heading>

      <i18n
        key="firstPublishedAt"
        tag="div"
        class="notification is-info p-a-s"
        path="components.story_edit_scheduled_publishing.published_originally_on"
      >
        <span slot="date">{{ datetime(firstPublishedAt) }}</span>
      </i18n>

      <div class="m-b-m">
        <div class="m-b-m">
          {{ $t('components.story_edit_scheduled_publishing.modify_date') }}
        </div>

        <div class="control m-b-m">
          <el-date-picker
            v-model="publishedAtTransformed"
            :picker-options="modifyPublishDateOptions"
            :clearable="false"
            class="StoryEditScheduledPublishing__datePicker"
            type="datetime"
            format="dd.MM.yyyy HH:mm"
          />

          <div class="m-v-s has-text-grey">
            {{ userTimezone }}
          </div>
        </div>

        <v-button
          :loading="isPublishing"
          class="is-unstyled has-text-decoration-underline"
          data-testid="StoryUnpublishButton"
          @click="unpublish"
        >
          {{ $t('components.story_edit_scheduled_publishing.unpublish_story') }}
        </v-button>
      </div>

      <div class="is-flex">
        <v-button
          class="is-plain is-dark is-width-1/2"
          data-testid="CancelButton"
          @click="cancel"
        >
          {{ $t('forms.cancel') }}
        </v-button>
        <v-button
          :disabled="!form.hasPropertyChanged('publishedAt')"
          class="is-primary is-width-1/2"
          data-testid="UpdatePublishAtDateButton"
          @click="save"
        >
          {{ isPublished ? $t('forms.update') : $t('forms.publish') }}
        </v-button>
      </div>
    </div>
    <div
      v-else
      class="is-flex is-column"
      data-testid="StoryScheduleOrPublishContainer"
    >
      <el-radio
        v-model="scheduledRadioButtonStatus"
        :label="false"
        data-testid="StoryPublishNowRadio"
        class="is-flex is-aligned-middle m-l-none m-b-xl"
      >
        <heading
          class="is-marginless is-gapless m-b-s"
          size="5"
        >
          {{ $t('components.story_edit_scheduled_publishing.publish_now') }}
        </heading>
        <div>{{ $t('components.story_edit_scheduled_publishing.publish_now_description') }}</div>
      </el-radio>

      <el-radio
        v-model="scheduledRadioButtonStatus"
        :disabled="!canSchedule"
        :label="true"
        data-testid="StoryScheduleRadio"
        class="is-flex is-aligned-middle m-l-none m-b-m"
      >
        <el-tooltip
          :disabled="canSchedule"
          :content="$t('components.story_edit_scheduled_publishing.cannot_schedule_after_publish')"
        >
          <heading
            class="is-marginless is-gapless m-b-s"
            size="5"
          >
            {{ $t('components.story_edit_scheduled_publishing.schedule_for_publishing_title') }}
          </heading>
        </el-tooltip>
        <div class="m-b-m">
          {{ $t('components.story_edit_scheduled_publishing.schedule_for_publishing_message') }}
        </div>

        <div
          v-show="scheduledRadioButtonStatus"
          data-testid="StoryScheduleNowContainer"
        >
          <div class="control">
            <el-date-picker
              v-model="publishAtTransformed"
              :picker-options="scheduledDatepickerOptions"
              :placeholder="$t('components.story_edit_scheduled_publishing.select_date_of_schedule_publishing')"
              :clearable="false"
              format="dd.MM.yyyy HH:mm"
              type="datetime"
              class="StoryEditScheduledPublishing__datePicker"
            />
            <div class="m-v-s has-text-grey">
              {{ userTimezone }}
            </div>
          </div>

          <v-button
            v-if="publishAt"
            class="is-plain is-dark"
            data-testid="StoryUnscheduleButton"
            @click="unschedule"
          >
            {{ $t('components.story_edit_scheduled_publishing.unschedule_story') }}
          </v-button>
        </div>
      </el-radio>
      <div
        class="is-flex"
        data-testid="StoryPublishScheduleButtons"
      >
        <v-button
          class="is-plain is-dark is-width-1/2"
          data-testid="CloseStorySchedulePublishing"
          @click="cancel"
        >
          {{ $t('forms.cancel') }}
        </v-button>

        <v-button
          v-if="scheduledRadioButtonStatus"
          :disabled="!publishAtTransformed"
          class="is-outlined is-primary is-width-1/2"
          data-testid="StoryScheduleNowButton"
          @click="schedule"
        >
          {{ $t('components.story_edit_scheduled_publishing.schedule') }}
        </v-button>

        <v-button
          v-if="!scheduledRadioButtonStatus"
          :loading="isPublishing"
          class="is-primary is-width-1/2"
          data-testid="StoryPublishNowButton"
          @click="publishNow"
        >
          {{ $t('forms.publish') }}
        </v-button>
      </div>
    </div>
  </div>
</template>

<script>
import DatetimeMixin from '@hypefactors/shared/js/mixins/DatetimeMixin'

/**
 * Renders the radio buttons when Scheduling a story to be published.
 * Used inside the {@see module:StoryEditAdminBarPublishButton}
 * @module StoryEditScheduledPublishing
 */
export default {
  name: 'StoryEditScheduledPublishing',

  mixins: [DatetimeMixin],

  props: {
    publishAt: {
      type: String,
      default: null
    },
    isPublished: {
      type: Boolean,
      required: true
    },
    isPublishing: {
      type: Boolean,
      default: false
    },
    firstPublishedAt: {
      type: String,
      default: ''
    },
    userTimezone: {
      type: String,
      default: ''
    },
    formPublishedAt: {
      type: [String, Number],
      default: null
    },
    formPublishAt: {
      type: [String, Number],
      default: null
    },
    form: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      scheduledRadioButtonStatus: false
    }
  },
  computed: {
    /**
     * Returns whether a story can be scheduled or not.
     * Should return false if it was published at all.
     * @return {boolean}
     */
    canSchedule () {
      return !this.firstPublishedAt
    },
    /**
     * Transforms the publishedAt to milliseconds
     */
    publishedAtTransformed: {
      get () { return this.formPublishedAt },
      set (v) { this.$emit('update:formPublishedAt', v ? v.getTime() : v) }
    },
    /**
     * Transforms the publishAt to milliseconds
     */
    publishAtTransformed: {
      get () { return this.formPublishAt },
      set (v) { this.$emit('update:formPublishAt', v ? v.getTime() : v) }
    },
    /**
     * Validation for the published_at dropdown.
     * Allows limiting the updating of the "published_at" to a date before "first_published_at"
     * @return {Object}
     */
    modifyPublishDateOptions () {
      const vm = this
      return {
        disabledDate (time) {
          return time > new Date(vm.firstPublishedAt)
        }
      }
    },
    /**
     * Limit the scheduled publish date to be in the future
     * @return {Object}
     */
    scheduledDatepickerOptions () {
      return {
        disabledDate (time) {
          return time < new Date()
        }
      }
    }
  },
  watch: {
    publishAt: {
      immediate: true,
      handler (value) {
        // set the radio's state based on the "publishAt" value on the Story
        this.scheduledRadioButtonStatus = this.canSchedule ? !!value : false
      }
    }
  },
  methods: {
    publishNow () {
      this.publishAtTransformed = null // always remove it if we are publishing now.
      // emit a publish event, that handles both saving first and publishing
      this.$emit('publish')
    },
    /**
     * Called when unpublishButton is clicked
     */
    unpublish () {
      this.publishAtTransformed = null // always remove it if we are publishing now.
      this.$emit('unpublish')
    },
    /**
     * Called when the Schedule button is clicked
     * Should do schedule date validations here
     * It calls "publish" with the "publishAt" field as payload
     */
    schedule () {
      this.$emit('publish')
    },
    /**
     * Updates the story publishedAt field
     */
    save () {
      this.$emit('save')
    },
    /**
     * Called when the UnscheduleStory button is clicked
     */
    async unschedule () {
      this.publishAtTransformed = null
      this.$emit('unpublish')
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.StoryEditScheduledPublishing {
  &__datePicker {
    width: 100% !important;
  }
}
</style>
