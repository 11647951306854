/**
 * @typedef {{job_description: string, phone: string, name: string, id: string, email: string, brand_id: string}} HF_Contact
 */

/**
 * Creates an empty HF_Contact
 * @return {HF_Contact}
 */
export function contactDataFactory () {
  return {
    id: '',
    name: '',
    job_description: '',
    email: '',
    brand_id: '',
    phone: ''
  }
}
