<template>
  <div
    v-loading="isLoading"
    :class="{'FileManagerItem--selected': isSelected}"
    class="FileManagerItem Collection-Item"
  >
    <div
      class="FileManagerItem__checkbox"
      data-testid="FileManagerItemCheckbox"
      @click="toggleSelection"
    >
      <i class="hf hf-fw hf-check FileManagerItem__check FileManagerItem__check--checked" />
      <i class="hf hf-fw hf-square-o FileManagerItem__check FileManagerItem__check--unchecked" />
    </div>

    <div class="FileManagerItem__download">
      <a
        :href="file.path"
        class="button is-round is-dark is-medium m-t-xs"
        target="_blank"
      >
        <span class="icon is-size-4">
          <v-icon type="download" />
        </span>
      </a>
    </div>
    <a
      href="#"
      class="FileManagerItem__delete button is-round is-dark is-medium m-t-xs"
      @click.prevent="deleteFile"
    >
      <span class="icon is-large has-text-white is-size-4">
        <v-icon type="trash" />
      </span>
    </a>

    <div
      :style="fileStyles"
      class="FileManagerItem__icon"
      data-testid="FileManagerItemIcon"
      @click="toggleSelection"
    >
      <i
        v-if="(hasPreview && !isImage) || (!hasPreview && isImage)"
        :class="[`hf-file-${ icons[file.type] }-o`]"
        class="hf"
      />
    </div>

    <div class="FileManagerItem__content">
      <div class="FileManagerItem__wrap">
        <div class="FileManagerItem__name">
          <span
            v-if="!isBeingEdited"
            data-testid="FileManagerItemName"
            @dblclick="startEditing"
          >
            {{ file.name }}
          </span>

          <form
            v-if="isBeingEdited"
            data-testid="FileManagerItemForm"
            @submit.prevent="updateFile"
          >
            <form-field
              :validator="$v.form.name"
              :attribute="$t('components.files_picker_item.file_name')"
              :class="{ 'is-loading': isLoading }"
              class="canLoad m-b-0"
            >
              <input
                ref="name"
                v-model="form.name"
                :placeholder="$t('components.files_picker_item.file_name')"
                class="input"
                type="text"
                data-testid="FileManagerItemNameInput"
                @input="$v.form.name.$touch()"
                @keyup.esc="isBeingEdited = false"
              >
            </form-field>

            <button
              type="submit"
              class="is-hidden"
            />
          </form>
        </div>

        <div class="FileManagerItem__updated_at">
          {{ file.updated_at }}
        </div>
      </div>

      <div
        v-if="!isBeingEdited"
        class="FileManagerItem__actions"
      >
        <a
          href="#"
          data-testid="FileManagerItemEditAction"
          @click.prevent="startEditing"
        >
          <v-icon type="pencil" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'

/**
 * Single file item.
 * @see module:FileManager
 * @module FileManagerItem
 */
export default {
  name: 'FileMangerItem',
  props: {
    file: {
      type: Object,
      default: () => ({})
    },

    isSelected: {
      type: Boolean,
      default: false
    },

    /**
     * Async Callback to update file data
     * @param {object} file
     * @param {object} form
     * @type {function}
     * @returns Promise<void>
     */
    update: {
      type: Function,
      required: true
    },

    /**
     * Async Callback to delete a file.
     * @param {object} file
     * @type {function(file:object): Promise<void>}
     */
    delete: {
      type: Function,
      default: () => Promise.resolve
    }
  },

  data () {
    return {
      isLoading: false,
      isBeingEdited: false,
      form: {
        name: ''
      },
      icons: {
        doc: 'pdf',
        image: 'image',
        audio: 'audio',
        video: 'video'
      }
    }
  },

  validations: {
    form: {
      name: { required, minLength: minLength(3) }
    }
  },

  computed: {
    isImage () {
      return this.file.type === 'image'
    },

    hasPreview () {
      return !!this.file.path
    },

    fileStyles () {
      const styles = {}
      if (this.isImage) {
        styles.backgroundImage = `url('${this.file.path}')`
      }
      return styles
    }
  },

  methods: {
    toggleSelection () {
      this.$emit('toggle')
    },

    async deleteFile () {
      try {
        await this.$confirm(this.$t('components.files_picker_item.confirm_file_delete'))
        this.isLoading = true
        const successMsg = this.$t('components.files_picker_item.delete_successful') // this.$t is not available after this.delete is called
        await this.delete(this.file)
        this.$notify.success(successMsg)
      } catch (err) {
        if (err === 'cancel') return
        this.$displayRequestError(err)
      } finally {
        this.isLoading = false
      }
    },

    startEditing () {
      this.form.name = this.file.name
      this.isBeingEdited = true
      this.$nextTick(() => {
        this.$refs.name.focus()
      })
    },

    /**
     * Updates the file's properties on the API
     * @return {Promise}
     */
    async updateFile () {
      this.isLoading = true

      try {
        if (this.update) {
          await this.update(this.file, this.form)
        }
        this.isBeingEdited = false
      } catch (error) {
        this.$displayRequestError(error)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss">
@import '~utils';

.FileManagerItem {
  @include mobile() {
    width: 50%;
    flex: 0 0 50%;
  }

  width: 20%;
  flex: 0 0 20%;
  overflow: hidden;
  text-align: center;
  color: $grey;
  position: relative;

  &__checkbox {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    border: 2px solid $grey;
    background: $white;
    cursor: pointer;
    .FileManagerItem__check {
      &--checked {
        display: none;
      }
    }
  }

  &__icon {
    font-size: 2rem;
    background: center/cover no-repeat;
    cursor: pointer;
  }

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    line-height: 1.3;
  }

  &__content {
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
    flex-flow: row wrap;
    position: relative;
  }

  &__wrap {
    flex: 1 0 auto;
    transition: all .3s ease-in;
    max-width: 100%;
  }

  &__actions {
    opacity: 0;
    transition: .15s;
    position: absolute;
    top: 0;
    right: 0;
    background: $white;
    padding: 0 0.5rem;
  }

  &:hover {
    color: $grey-light;
    .FileManagerItem__actions {
      opacity: 1;
    }
  }

  &--selected {
    .FileManagerItem__checkbox {
      border-color: transparent !important;
      background: $primary !important;
      .FileManagerItem__check {
        &--checked {
          display: block;
        }
        &--unchecked {
          display: none;
        }
      }
    }
  }
}

</style>
