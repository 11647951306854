<template>
  <transition-group name="contact-list" tag="div" class="contact-list columns">
    <contact-list-item
      v-for="contact in storyContacts"
      :key="contact.id"
      :contact="contact"
      class="column is-4"
      @removed="removeContact"
      @update="updateContactsOnListUpdate"
    />
    <div
      v-if="canAddNewContact"
      key="newContact"
      class="column is-4 contact-list__item contact-list__item--add-new"
    >
      <contact-list-add-new
        :all-contacts="allContacts"
        :story-contacts="storyContacts"
        :show-create-new-contact.sync="showCreateNewContact"
        :selected-contact-from-list="selectedContactFromList"
        @save="saveNewContact"
        @cancel="resetEditableContactCard"
        @selectionChange="addNewContactFromDropdown"
      />
    </div>
  </transition-group>
</template>
<script>

import contactListItem from './ContactListItem.vue'
import ContactListAddNew from '@/components/stories/ContactListAddNew'

/**
 * Renders a list of brand contacts
 * Allows updating, cloning and adding new contacts
 * @module ContactList
 */
export default {
  name: 'ContactList',
  components: {
    contactListItem,
    ContactListAddNew
  },
  model: {
    prop: 'storyContacts',
    event: 'change'
  },
  props: {
    /**
     * Array of contacts applied to the story
     * @type HF_Contact[]
     */
    storyContacts: {
      type: Array,
      required: true
    },
    /**
     * All the contacts for the brand
     * @type HF_Contact[]
     */
    allContacts: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selectedContactFromList: null,
      showCreateNewContact: false
    }
  },
  computed: {
    canAddNewContact () {
      return this.storyContacts.length < 3
    }
  },
  methods: {
    /**
     * Sets the showCreateNewContact variable
     * @param {Boolean} state
     */
    changeNewCardVisibility (state) {
      this.showCreateNewContact = state
    },

    /**
     * Hides the create new contact card interface
     */
    resetEditableContactCard () {
      this.changeNewCardVisibility(false)
      // reset the temp contact
      this.selectedContactFromList = null
    },

    /**
     * Adds a new contact to the list of story contacts and pushed it to the brand
     * @param {HF_Contact} newContact
     */
    saveNewContact (newContact) {
      // Add the newly created contact to the list of contacts
      const allContacts = this.allContacts.concat([newContact])

      this.pushNewContactToStory(newContact)
      this.pushAllContactsToStory(allContacts)
      this.resetEditableContactCard()
    },

    /**
     * Removes a contact from the story contacts list
     * @param {HF_Contact} contact
     */
    removeContact (contact) {
      const contacts = this.storyContacts.filter(c => c.id !== contact.id)
      this.$emit('change', contacts)
    },

    /**
     * Updates the story contacts when an item in the list updates
     * @param {HF_Contact} changedContact
     * @param {HF_Contact} oldContact
     */
    updateContactsOnListUpdate ({ changedContact, oldContact }) {
      let storyContacts = this.storyContacts.slice(0)
      // Find the old contacts Index
      const contactIndex = storyContacts.findIndex(c => c.id === oldContact.id)
      // Replace old contact with changed one
      storyContacts[contactIndex] = changedContact

      let allContacts = this.allContacts.slice(0)

      if (changedContact.id !== oldContact.id) {
        allContacts.push(changedContact)
      } else {
        const contactIndexInAllContacts = allContacts.findIndex(c => c.id === oldContact.id)
        allContacts[contactIndexInAllContacts] = changedContact
      }

      this.$emit('change', storyContacts)
      this.pushAllContactsToStory(allContacts)
    },

    /**
     * Sends the newly added story to the parent story
     * @param {HF_Contact} contact
     * @emits change
     */
    pushNewContactToStory (contact) {
      const contacts = this.storyContacts.concat([contact])
      this.$emit('change', contacts)
    },

    /**
     * Sends all contacts to the parent story
     * @param {HF_Contact[]} allContacts
     */
    pushAllContactsToStory (allContacts) {
      this.$emit('update:allContacts', allContacts)
    },

    /**
     * Adds a new contact from the dropdown list
     * @param {HF_Contact} contact
     */
    addNewContactFromDropdown (contact) {
      this.pushNewContactToStory(contact)
      // clear after selecting
      this.selectedContactFromList = null
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.contact-list {
  position: relative;

  &__item {
    transition: all 0.5s;
    display: flex;

    &--add-new {
      z-index: 1;
    }
  }

  .contact-card {
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
    max-width: 100%;

    &__cover {
      width: 120px;
      margin: auto;
      max-width: 100%;

      &-inner {
        height: 0;
        padding-bottom: 100%;
        border-radius: 50%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-color: $grey-lighter;
      }
    }

    &__content {
      flex: 1 1 auto;
      padding-left: 0;
      font-size: $size-6;
      @include rtl {
        padding-right: 0;
        padding-left: 1.5rem;
      }
    }

    &__footer {
      padding: 8px;
      display: flex;
      flex-flow: row wrap;

      .button.is-plain {
        padding-left: 8px;
        padding-right: 8px;

        .icon {
          min-width: 36.5px !important;
        }
      }

      .contact-card__button-clone {
        margin-left: auto !important;
      }
    }

    &__data-row {
      border-bottom: $hf__border--base;
      padding-left: 1rem;
      margin-bottom: 1rem;
      padding-bottom: .5rem;
      overflow: hidden;
      text-overflow: ellipsis;

      @include rtl {
        padding-left: 0;
        padding-right: 1rem;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      .placeholder {
        color: $grey;
      }
    }

    &__input {
      margin-bottom: 0.4rem;

      &.is-error {
        margin-bottom: 1.4rem;
      }
    }

    &--is-edited {
      .img-upload {
        margin-bottom: 0;
      }

      .img-upload__inner {
        margin: auto;
      }
    }
  }
}

#contacts.section {
  min-height: 410px;
}

/* Vue List Transitions */

.contact-list {
  &-enter, &-leave-to {
    opacity: 0;
  }

  &-leave-active {
    position: absolute;
    height: calc(100% - 10px);
    @include desktop {
      width: 33.3%;
    }
  }
}
</style>
