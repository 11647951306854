<template>
  <div class="StoryEditPinned">
    <el-popover
      placement="bottom"
      width="300"
      trigger="click"
      popper-class="StoryEditAdminBar__dropdown"
    >
      <dashboard-filters-v2-item slot="reference" class="is-cursor-pointer">
        <dashboard-filters-icon
          :label="$t('components.story_edit_admin_bar.display_on_newsroom')"
          data-testid="StoryEditAdminBar__PinTrigger"
          icon="pin"
        />
      </dashboard-filters-v2-item>
      <div class="p-a-m">
        <el-radio
          v-model="isPinned"
          :label="false"
          class="StoryEditPinned__choice"
          data-testid="StoryEditPinned--NotPinned"
        >
          <div class="StoryEditPinned__choiceTitle">
            {{ $t('general.normal') }}
          </div>
          <div>{{ $t('pages.story_settings.section_story_display_normal_description') }}</div>
          <img
            :src="$asset('/images/sections/stories/not_pinned.png')"
            :alt="$t('general.not_pinned')"
          >
        </el-radio>
        <el-radio
          v-model="isPinned"
          :label="true"
          class="StoryEditPinned__choice"
          data-testid="StoryEditPinned--Pinned"
        >
          <div class="StoryEditPinned__choiceTitle">
            {{ $t('general.pinned') }}
          </div>
          <div>{{ $t('pages.story_settings.section_story_display_pinned_description') }}</div>
          <img
            :src="$asset('/images/sections/stories/pinned.png')"
            :alt="$t('general.pinned')"
          >
        </el-radio>
      </div>
    </el-popover>
  </div>
</template>

<script>
import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'

/**
 * Sets the pinned status on the story
 * @module StoryEditPinned
 */
export default {
  name: 'StoryEditPinned',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    isPinned: generateComputedSetterWithEmit()
  }
}
</script>

<style lang='scss'>
@import '~utils';

.StoryEditPinned__choice {
  display: flex;
  margin-left: 0 !important;
  margin-bottom: $margin;
  white-space: normal;
  text-align: left;
  line-height: 1.3;

  @include rtl {
    margin-right: 0 !important;
    text-align: right;
  }

  .el-radio__label {
    font-size: $size-6;
  }
}

.StoryEditPinned__choiceTitle {
  margin-bottom: $margin-small;
}
</style>
