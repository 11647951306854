<template>
  <div class="StoryEditModeSwitch is-flex is-aligned-middle">
    <div class="m-r-m is-size-4">
      <help-tooltip>
        <div class="m-b-m">
          {{ $t('components.story_edit_mode_switch.tooltip_1') }}
        </div>
        <div>{{ $t('components.story_edit_mode_switch.tooltip_2') }}</div>
      </help-tooltip>
    </div>
    <div>
      <restricted-button
        :action-class="[PRMode ? 'is-black' : 'is-grey', 'is-outlined button']"
        permission="email.create"
        data-testid="StoryEditPRModeButton"
        @click="PRMode = true"
      >
        {{ $t('components.story_edit_mode_switch.press_release') }}
      </restricted-button>
      <v-button
        :class="[PRMode ? 'is-grey' : 'is-black', 'is-outlined']"
        class="m-l-m"
        data-testid="StoryEditStoryModeButton"
        @click="PRMode = false"
      >
        {{ $t('components.story_edit_mode_switch.newsroom_story') }}
      </v-button>
    </div>
  </div>
</template>

<script>
import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'

/**
 * @module StoryEditModeSwitch
 */
export default {
  name: 'StoryEditModeSwitch',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    PRMode: generateComputedSetterWithEmit()
  }
}
</script>
