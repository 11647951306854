<template>
  <el-tooltip
    :disabled="!isDisabled"
    :content="$t('components.story_edit_pr_preview_button.tooltip_warning')"
  >
    <v-button
      :disabled="isDisabled"
      class="StoryEditPrPreviewButton is-unstyled"
      data-testid="StoryEditPrPreviewButton"
      @click="isPRPreviewDialogVisible = true"
    >
      <dashboard-filters-icon
        :is-dropdown="false"
        :label="$t('general.preview')"
        icon="preview"
      />
    </v-button>
  </el-tooltip>
</template>

<script>
import { PRPreviewDialogGetters, PRPreviewDialogMethods } from '@/store/modules/newsroom/helpers'

/**
 * @module StoryEditPrPreviewButton
 */
export default {
  name: 'StoryEditPrPreviewButton',

  props: {
    isNewStory: {
      type: Boolean,
      default: true
    },
    hasUnsavedChanges: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    ...PRPreviewDialogGetters,

    isDisabled () {
      return this.isNewStory || this.hasUnsavedChanges
    }
  },

  methods: {
    ...PRPreviewDialogMethods
  }
}
</script>
