<template>
  <restricted-button
    :title="$t('forms.delete_story')"
    :loading="isDeleting"
    :disabled="isNewStory"
    permission="story.delete"
    tooltip-text="components.story_edit_admin_bar.delete_button_disabled_tooltip"
    tag="button"
    action-class="button is-unstyled"
    data-testid="StoryDeleteButton"
    @click="deleteStory"
  >
    <dashboard-filters-icon :is-dropdown="false" :label="$t('general.delete')" icon="trash" />
  </restricted-button>
</template>

<script>

/**
 * Renders the delete story button in the {@see module:StoryEditAdminBar}
 * @module StoryEditAdminBarDeleteStory
 */
import { StoryApiService } from '@hypefactors/shared/js/services/api/StoryApiService'

export default {
  name: 'StoryEditAdminBarDeleteStory',
  props: {
    /**
     * @type HF_Story
     */
    story: {
      type: Object,
      required: true
    },
    isNewStory: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isDeleting: false
    }
  },

  methods: {
    async deleteStory () {
      try {
        await this.$confirm(this.$t('pages.story_edit.sidebar_sticky_panel.confirm_story_delete'))
        this.isDeleting = true
        await StoryApiService.deleteStory(this.story.id)
        this.$emit('delete', this.story)
        this.$notify.success(this.$t('success.story_deleted'))
      } catch (error) {
        if (error === 'cancel') {
          return
        }
        this.$displayRequestError(error, this.$t('errors.cannot_delete_story'))
      } finally {
        this.isDeleting = false
      }
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.StoryEditAdminBarDeleteStory {
  font-weight: normal;
  padding-left: 0;
  text-transform: none;
  width: 100%;
  justify-content: flex-start;
}
</style>
