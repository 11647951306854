<template>
  <div class="StoryEditPrCustomStyleBar is-flex">
    <dropdown-list
      :elements="PRFontSizes"
      :selected-elements="[mergedStyles.fontSize]"
      :handler="(item) => updateStyle('fontSize', item)"
      menu-classes="PRCustomStyleFontSizeMenu"
    >
      <div slot="trigger">
        <a
          class="button is-plain is-dark is-medium is-narrow"
          tabindex="-1"
          data-testid="PRCustomStyleFontSizeTrigger"
        >
          <span class="icon">
            <i class="fa fa-fw fa-font" />
          </span>
          <i class="fa fa-caret-down" />
        </a>
      </div>
    </dropdown-list>
    <dropdown-list
      :elements="PRFontFamilies"
      :selected-elements="[mergedStyles.fontFamily]"
      :handler="(item) => updateStyle('fontFamily', item)"
      is-active-prop="value"
      value-key="value"
      label-key="label"
      menu-classes="PRCustomStyleFontFamilyMenu"
    >
      <div slot="trigger">
        <a
          class="button is-plain is-dark is-medium is-narrow"
          tabindex="-1"
          data-testid="PRCustomStyleFontFamilyTrigger"
        >
          <span class="icon">
            <i class="fa fa-fw fa-text-height" />
          </span>
          <i class="fa fa-caret-down" />
        </a>
      </div>
    </dropdown-list>
  </div>
</template>

<script>
import { HF_CUSTOM_STYLE_FONT_FAMILIES, HF_CUSTOM_FONT_SIZES } from '@hypefactors/shared/js/constants/fonts'

/**
 * Renders a custom style bar that is used on the {@see module:StoryEditV2} to allow custom styles to be applied to Headline and Subheading fields
 * @module StoryEditPrCustomStyleBar
 */
export default {
  name: 'StoryEditPrCustomStyleBar',

  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    mergedStyles: {
      type: Object,
      default: () => ({ fontSize: '', fontFamily: '' })
    }
  },

  data () {
    return {
      PRFontSizes: HF_CUSTOM_FONT_SIZES(),
      PRFontFamilies: HF_CUSTOM_STYLE_FONT_FAMILIES()
    }
  },

  methods: {
    updateStyle (styleProperty, value) {
      this.$emit('input', Object.assign({}, this.value, { [styleProperty]: value }))
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.StoryEditPrCustomStyleBar {
  background: $grey-light;
  border-radius: 2px;
  color: $dark;
  z-index: 4;
  width: 100%;
  line-height: 1;

  .dropdown-menu {
    padding-top: 0;
    top: 95%;
    min-width: 0;
    font-family: $family-primary;

    .dropdown-content {
      border-radius: 0;
    }

    .dropdown-item {
      &.is-active {
        background: $grey-lighter;
        color: $grey-darker;
      }
    }
  }
}
</style>
