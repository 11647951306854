<template>
  <div
    v-if="categories.length"
    class="StoryCategoriesList"
  >
    <div
      v-for="cat in categories"
      :key="cat.id"
      class="Category is-inline-block-mobile"
    >
      {{ $t('categories.' + cat.id) }} <span class="Category__separator is-hidden-tablet">|</span>
    </div>
  </div>
</template>

<script>
/**
 * @module StoryCategoriesList
 */
export default {
  name: 'StoryCategoriesList',
  props: {
    categories: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.StoryCategoriesList {
  font-size: $size-6;
  @include tablet {
    font-size: $size-5;
    margin-bottom: $margin*1.2;
  }

  .Category {
    color: $hf__heading-color;

    &:last-child {
      .Category__separator {
        display: none;
      }
    }
  }
}
</style>
