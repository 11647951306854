<template>
  <div class="StoryEditTags">
    <heading
      size="6"
      weight="bold"
      :serif="false"
      class="m-b-none"
    >
      {{ $t('general.tags') }}
    </heading>

    <story-tags-list
      v-if="storyTags.length"
      :tags="storyTags"
      class="m-b-m is-size-5"
    />

    <v-button
      class="is-black is-full-width"
      data-testid="StoryEditTagsTrigger"
      @click="startEditing"
    >
      {{ $t('components.story_edit_tags.set_tags') }}
    </v-button>

    <el-dialog
      :visible.sync="isEditing"
      :title="$t('components.story_edit_tags.title')"
      data-testid="StoryEditTagsDialog"
      class="is-fullscreen-mobile"
      @close="stopEditing"
    >
      <div class="m-b-l is-size-6">
        {{ $t('components.story_edit_tags.body') }}
      </div>

      <tags-picker
        v-model="storyTags"
        :suggestions="tags"
      />
    </el-dialog>
  </div>
</template>

<script>
import TagsPicker from '@/components/stories/TagsPicker'
import { StoryApiService } from '@hypefactors/shared/js/services/api/StoryApiService'
import StoryTagsList from '@hypefactors/shared/js/components/story/StoryTagsList'
import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'

/**
 * Creates an interface with dialog to Edit Story Categories
 * @module StoryEditTags
 */
export default {
  name: 'StoryEditTags',

  components: { TagsPicker, StoryTagsList },

  props: {
    value: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      isEditing: false,
      tags: []
    }
  },

  computed: {
    storyTags: generateComputedSetterWithEmit()
  },

  watch: {
    activeBrandId: {
      immediate: true,
      handler: 'fetchSuggestedTags'
    }
  },

  methods: {
    async fetchSuggestedTags () {
      this.tags = await StoryApiService.fetchSuggestedTags()
    },

    startEditing () {
      this.isEditing = true
    },

    stopEditing () {
      this.isEditing = false
    }
  }
}
</script>
