<template>
  <div class="FileManagerFilters__actionsBar columns">
    <div class="column is-narrow">
      <div class="control">
        <input
          v-model="searchProp"
          :placeholder="$t('components.files_picker_filter.filter_by_name')"
          class="input"
          type="text"
          name="filter"
          data-testid="FileMangerFilterInput"
        >
      </div>
    </div>

    <div class="column is-narrow">
      <div class="control">
        <el-select
          v-model="typeProp"
          :placeholder="$t('components.files_picker_filter.file_types_label')"
          clearable
        >
          <el-option
            v-for="fileType in fileTypes"
            :key="fileType.type"
            :label="fileType.label"
            :value="fileType.type"
          />
        </el-select>
      </div>
    </div>

    <div class="column is-narrow m-l-a">
      <div
        class="FileManagerFilters__displayTypes"
        data-testid="FileManagerFiltersDisplayTypes"
      >
        <div
          v-for="disType in displayTypes"
          :key="disType.name"
          :class="[`FileManagerFilters__displayType--${disType.name}`,{'FileManagerFilters__displayType--selected': disType.name === displayTypeValue}]"
          :title="$t('forms.view_as_' + disType.name)"
          class="FileManagerFilters__displayType"
          data-testid="FileManagerFiltersDisplayType"
          @click="displayTypeValue = disType.name"
        >
          <i :class="[`hf-${disType.icon}`]" class="hf" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

/**
 * Allows filtering of brand files.
 * @see module:FileManager
 * @module FileManagerFiltersFilters
 */
export default {
  name: 'FileManagerFilters',
  props: {
    search: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: null
    },
    displayType: {
      type: String,
      default: 'grid'
    }
  },

  data () {
    return {
      fileTypes: [
        { type: 'doc', label: this.$t('components.files_picker_filter.file_types.documents') },
        { type: 'image', label: this.$t('components.files_picker_filter.file_types.images') },
        { type: 'video', label: this.$t('components.files_picker_filter.file_types.video') },
        { type: 'audio', label: this.$t('components.files_picker_filter.file_types.audio') }
      ],
      displayTypes: [{ name: 'list', icon: 'list' }, { name: 'grid', icon: 'th' }]
    }
  },

  computed: {
    displayTypeValue: {
      get () {
        return this.displayType
      },
      set (value) {
        this.$emit('update:displayType', value)
      }
    },

    searchProp: {
      get () {
        return this.search
      },
      set (value) {
        this.$emit('update:search', value)
      }
    },

    typeProp: {
      get () {
        return this.type
      },
      set (value) {
        this.$emit('update:type', value)
      }
    }
  }
}
</script>

<style lang="scss">
@import '~utils';

.FileManagerFilters {
  &__displayTypes {
    display: flex;
    align-items: center;
    height: 100%;
  }
  &__displayType {
    background: none;
    margin-bottom: 0;
    border: none;
    cursor: pointer;
    transition: .15s ease-in;
    padding: 0 .5rem;
    &--selected {
      color: $primary;
    }
  }
}
</style>
