<template>
  <el-tooltip
    :disabled="!isDisabled"
    :content="tooltipContent"
  >
    <el-popover
      v-model="isDropdownVisible"
      :disabled="isNewStory"
    >
      <v-button
        slot="reference"
        :disabled="isDisabled"
        class="is-primary has-icon"
        data-testid="StoryEditScheduledPublishingTrigger"
      >
        <span>{{ $t('forms.publish') }}</span>
        <span class="icon">
          <v-icon :type="isDropdownVisible ? 'angle-up':'angle-down'" />
        </span>
      </v-button>
      <story-edit-scheduled-publishing
        :is-publishing="isPublishing"
        :form-publish-at.sync="form.publishAt"
        :form-published-at.sync="form.publishedAt"
        :form="form"
        :publish-at="story.publishAt"
        :is-published="story.isPublished"
        :first-published-at="story.firstPublishedAt"
        @save="$emit('save')"
        @cancel="isDropdownVisible = false"
        @publish="$emit('publish')"
        @unpublish="$emit('unpublish')"
      />
    </el-popover>
  </el-tooltip>
</template>

<script>

import StoryEditScheduledPublishing from '@/components/stories/StoryEdit/StoryEditScheduledPublishing'

/**
 * Switches between a publish and unpublish button
 * @module StoryEditAdminBarPublishButton
 */
export default {
  name: 'StoryEditAdminBarPublishButton',
  components: { StoryEditScheduledPublishing },
  props: {
    isNewStory: {
      type: Boolean,
      default: true
    },
    story: {
      type: Object,
      default: null
    },
    form: {
      type: Object,
      default: null
    },
    isPublishing: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isDropdownVisible: false
    }
  },
  computed: {
    isDisabled () {
      return this.isNewStory || !this.form.newsroom_id
    },
    tooltipContent () {
      if (this.isNewStory) return this.$t('components.story_edit_admin_bar.save_story_before_publishing')
      if (!this.form.newsroom_id) return this.$t('components.story_edit_admin_bar.choose_newsroom_before_publishing')
      return ''
    }
  },
  mounted () {
    this.$bus.$on('StoryEditV2:save', () => {
      this.isDropdownVisible = false
    })
  },
  beforeDestroy () {
    this.$bus.$off('StoryEditV2:save')
  }
}
</script>
