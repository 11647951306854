<template>
  <el-popover
    placement="bottom"
    width="300"
    trigger="click"
    popper-class="StoryEditAdminBar__dropdown"
  >
    <dashboard-filters-v2-item slot="reference" class="is-cursor-pointer">
      <dashboard-filters-icon
        :label="$t('components.story_edit_admin_bar.pr_style_template')"
        data-testid="StoryEditAdminBar__PinTrigger"
        icon="templates"
      />
    </dashboard-filters-v2-item>
    <div class="control">
      <basic-select
        v-model="activePRTemplateId"
        :values="PRTemplates"
        :placeholder="$t('components.story_edit_admin_bar.pr_style_template')"
        key-prop="id"
        value-prop="id"
        label-prop="name"
        class="StoryEditPrStyleTemplatePicker"
      >
        <div slot-scope="{ item }">
          {{ item.name }}
        </div>
      </basic-select>
    </div>
  </el-popover>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'

/**
 * Renders a press releases picker component
 * @module StoryEditPrStyleTemplatePicker
 */
export default {
  name: 'StoryEditPrStyleTemplatePicker',

  props: {
    value: {
      type: Number,
      default: null
    }
  },

  computed: {
    ...mapState('newsroom', {
      PRTemplates: 'brandPRStyleTemplates'
    }),
    ...mapGetters('newsroom', {
      primaryTemplate: 'primaryPRStyleTemplate'
    }),

    activePRTemplateId: generateComputedSetterWithEmit()
  },

  mounted () {
    this.setDefaultPRTemplate()
  },

  methods: {
    setDefaultPRTemplate () {
      if (!this.activePRTemplateId && this.primaryTemplate) {
        this.activePRTemplateId = this.primaryTemplate.id
      }
    }
  }
}
</script>
