<template>
  <v-button
    :loading="isLoading"
    :class="{ 'has-text-primary': hasChanges }"
    class="is-unstyled"
    @click="$emit('save')"
  >
    <dashboard-filters-icon icon="download" :is-dropdown="false">
      <template #label>
        {{ $t('forms.save') }}
        <span v-if="hasChanges" class="has-text-weight-bold">*</span>
      </template>
    </dashboard-filters-icon>
  </v-button>
</template>

<script>

/**
 * @module StoryEditAdminBarSaveStory
 */
export default {
  name: 'StoryEditAdminBarSaveStory',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    hasChanges: {
      type: Boolean,
      default: false
    }
  }
}
</script>
