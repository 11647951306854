<template>
  <div class="CategoriesPicker">
    <div class="field-block">
      <div class="select_categories">
        <div class="columns is-gapless is-marginless">
          <div class="column is-4">
            <form-field
              :label="$t('components.story_category_picker.main_category')"
              :force-error="requiredCategory[0]"
              class="Newsroom_Category_Lvl1 m-b-m"
              required
            >
              <el-select
                ref="category_1"
                v-model="category_1"
                :filterable="true"
                class="NewsroomCategoryMain"
                popper-class="CategoryMainDropdown"
                value-key="id"
                @change="selectCategory($event, 1)"
              >
                <el-option
                  v-for="category in level1Categories"
                  :key="category.id"
                  :label="$t('categories.' + category.id)"
                  :value="category"
                />
              </el-select>
            </form-field>
          </div>

          <div class="column is-4">
            <form-field
              :label="$t('components.story_category_picker.sub_category')"
              :force-error="requiredCategory[1]"
              class="Newsroom_Category_Lvl2 m-b-m"
              required
            >
              <el-select
                ref="category_2"
                v-model="category_2"
                :filterable="true"
                :disabled="!category_1"
                value-key="id"
                class="NewsroomCategorySub"
                popper-class="CategorySubDropdown"
                @change="selectCategory($event, 2)"
              >
                <el-option
                  v-for="category in level2Categories"
                  :key="category.id"
                  :label="$t('categories.' + category.id)"
                  :value="category"
                />
              </el-select>
            </form-field>
          </div>

          <div class="column is-4">
            <form-field
              :label="$t('components.story_category_picker.specialized_category')"
              :force-error="requiredCategory[2]"
              class="Newsroom_Category_Lvl3 m-b-m"
              required
            >
              <el-select
                ref="category_3"
                v-model="level3SelectedCategories"
                :disabled="!category_2"
                value-key="id"
                class="NewsroomCategorySpec"
                popper-class="CategorySpecDropdown has-checkboxes"
                filterable
                multiple
                collapse-tags
              >
                <el-option
                  v-for="category in level3Categories"
                  :key="category.id"
                  :label="$t('categories.' + category.id)"
                  :value="category"
                />
              </el-select>
            </form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="field-block">
      <!-- Selected Categories -->
      <div
        v-if="value.length"
        class="categories_wrap"
      >
        <div>
          <ul
            v-for="(category, index) in value"
            :key="category.id"
            class="categories"
          >
            <li
              v-for="(cat, catIndex) in category.path"
              :key="cat.id"
              :class="{ 'last-item': catIndex + 1 === category.path.length }"
              class="category"
              v-text="$t('categories.' + cat.id)"
            />

            <li class="remove">
              <div class="icon">
                <i
                  :title="$t('components.story_category_picker.remove_set')"
                  class="hf hf-bin hf-lg"
                  @click="removeCategory(index)"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryService from '@hypefactors/shared/js/services/CategoryService'

export default {
  name: 'CategoriesPicker',

  props: {
    value: {
      type: Array,
      required: true
    },
    categories: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      category_1: null,
      category_2: null,
      category_3: null
    }
  },

  computed: {
    level1Categories () {
      return this.categories
    },

    level2Categories () {
      return this.category_1
        ? this.category_1.children.data
        : []
    },

    level3Categories () {
      if (!this.category_2) {
        return []
      }

      // Filter out the current selected categories
      return this.category_2.children.data
      // .filter(category => !this.value.find(selected => selected.id === category.id))
    },

    level3SelectedCategories: {
      get () {
        if (!this.category_2) return []
        return this.value
        // filter the value prop to just the selected level 2 items
          .filter(c => c.path[1].id === this.category_2.id)
          // return just the last item
          .map(c => c.path[c.path.length - 1])
      },
      set (value) {
        this.selectSpecializedCategory(value)
      }
    },

    /**
     * Returns currently required category
     * @returns {{0: boolean, 1: boolean, 2: boolean}}
     */
    requiredCategory () {
      if (this.value.length) return { 0: false, 1: false, 2: false }
      return {
        0: !this.category_1 && !this.category_2 && !this.category_3,
        1: !!this.category_1 && !this.category_2,
        2: !!this.category_2 && !this.category_3
      }
    }
  },

  watch: {
    category_1 (category) {
      this.category_2 = null
    }
  },

  methods: {
    async removeCategory (index) {
      try {
        await this.$confirm(this.$t('components.story_newsroom_edit.newsroom_edit_categories.confirm_removal'))
        this.$emit('input', [
          ...this.value.slice(0, index),
          ...this.value.slice(index + 1)
        ])
      } catch (err) { }
    },

    async selectCategory (category, depth) {
      if (!category) {
        return
      }

      this.$refs[`category_${depth}`].blur()
      await this.$nextTick()
      this.$refs[`category_${depth + 1}`].handleFocus()
    },

    selectSpecializedCategory (categories) {
      // remove category level 2 items that are equal to the current chosen category_2
      const removedCurrentSecondLevel = this.value.filter(category => category.path[1].id !== this.category_2.id)
      const updatedArray = [
        ...removedCurrentSecondLevel,
        ...categories.map(category => CategoryService.getCategoryWithPath(category.id))
      ]
      this.$emit('input', updatedArray)
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.CategoriesPicker {
  .categories_wrap {
    margin-top: $margin;

    .categories {
      list-style: none;
      margin: 0;
      padding: 10px 0;
      border-bottom: 1px #ccc solid;
      display: flex;
      flex-flow: row nowrap;
      overflow-x: auto;
      align-items: stretch;

      &:last-child {
        border-bottom: 0;
      }

      .category {
        display: flex;
        align-items: center;
        padding: 6px 10px 6px 0px;
        order: 2;
        text-align: center;

        &:after {
          content: '>';
          margin-left: 10px;
        }

        &.last-item {
          &::after {
            display: none;
          }
        }
      }

      .remove {
        display: flex;
        align-items: center;
        margin-left: 0;
        padding: 6px 10px;
        line-height: 1;
        order: 1;
        cursor: pointer;
        background: $white;
        color: $primary;
        left: 0;
        position: sticky;
      }
    }
  }
}
</style>
