<template>
  <div class="contact-list__item">
    <div v-if="!isBeingEdited" key="card" class="contact-card card">
      <div class="card-content">
        <div class="contact-card__cover">
          <div :style="coverImageStyles" class="contact-card__cover-inner" />
        </div>
      </div>
      <div class="card-content contact-card__content">
        <data-row :value="contact.name" :placeholder="$t('general.fullname')" />
        <data-row :value="contact.job_description" :placeholder="$t('general.job_description')" />
        <data-row :value="contact.email" :placeholder="$t('forms.contact_email')" />
        <data-row :value="contact.phone" :placeholder="$t('general.phone_number')" />
      </div>
      <div class="card-footer contact-card__footer">
        <el-tooltip :content="$t('components.story_edit.contact_list_item.detach_contact_from_story')" placement="top">
          <button
            class="button is-plain is-danger"
            @click="removeContact"
          >
            <span class="icon">
              <i class="hf hf-trash hf-lg" />
            </span>
          </button>
        </el-tooltip>
        <el-tooltip :content="$t('components.story_edit.contact_list_item.edit_contact')" placement="top">
          <button
            class="button is-plain is-dark"
            @click="editContact"
          >
            <span class="icon">
              <i class="hf hf-pencil hf-lg" />
            </span>
          </button>
        </el-tooltip>
        <el-tooltip :content="$t('components.story_edit.contact_list_item.use_contact_as_template')" placement="top">
          <button
            class="button is-plain is-dark contact-card__button-clone"
            @click="cloneContact"
          >
            {{ $t('forms.clone') }}
          </button>
        </el-tooltip>
      </div>
    </div>
    <contact-list-item-editable
      v-if="isBeingEdited"
      key="card-edit"
      :contact="tempContact"
      @save="updateContactInParent"
      @cancel="cancelCardEditing"
    />
  </div>
</template>
<script>
import contactListItemEditable from './ContactListItemEditable.vue'
import contactListItemDataRow from './ContactListItemDataRow.vue'

export default {
  components: {
    contactListItemEditable,
    dataRow: contactListItemDataRow
  },
  props: {
    contact: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isBeingEdited: false,
      tempContact: null
    }
  },
  computed: {
    coverImage () {
      return this.$safeGet(this.contact, 'image.cropped') || ''
    },
    coverImageStyles () {
      if (!this.coverImage) return {}
      return {
        backgroundImage: `url('${this.coverImage}')`
      }
    }
  },
  methods: {
    removeContact () {
      this.$emit('removed', this.contact)
    },
    updateContactInParent (changedContact) {
      this.isBeingEdited = false
      this.tempContact = null
      this.$emit('update', { changedContact, oldContact: this.contact })
    },
    editContact () {
      this.$confirm(this.$t('components.story_edit.contact_list_item.confirm_update_contact'))
        .then(() => {
          this.tempContact = Object.assign({}, this.contact)
          this.isBeingEdited = true
        })
        .catch(() => {})
    },
    cloneContact () {
      this.tempContact = Object.assign({}, this.contact, { id: null, clone: true })
      this.isBeingEdited = true
    },
    cancelCardEditing () {
      this.tempContact = null
      this.isBeingEdited = false
    }
  }
}
</script>
