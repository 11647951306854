<template>
  <contact-list
    v-model="contacts"
    v-loading="isLoading"
    :all-contacts.sync="brandContacts"
  />
</template>

<script>
import ContactList from '@/components/stories/ContactList'

import { StoryApiService } from '@hypefactors/shared/js/services/api/StoryApiService'
import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'

/**
 * Fetches the brand Contacts and renders an interface to edit them
 * @module StoryEditContacts
 */
export default {
  name: 'StoryEditContacts',

  components: { ContactList },

  props: {
    /**
     * Am Array of contacts attached to the Story
     */
    value: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      brandContacts: [],
      isLoading: false
    }
  },

  computed: {
    contacts: generateComputedSetterWithEmit()
  },

  watch: {
    activeBrandId: {
      immediate: true,
      handler: 'fetchBrandContacts'
    }
  },

  methods: {
    /**
     * Fetches the Brand's Contacts
     * @returns {Promise<void>}
     */
    async fetchBrandContacts () {
      this.isLoading = true
      try {
        this.brandContacts = await StoryApiService.fetchBrandContacts()
      } catch (e) {
        this.$displayRequestError(e)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
