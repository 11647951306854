import Form from '@/services/forms/Form'
import { croppieDataFactory } from '@hypefactors/shared/js/factories/croppie'
import CategoryService from '@hypefactors/shared/js/services/CategoryService'
import { requiredExcludingSpecialChars, sanitizeStringForValidator } from '@hypefactors/shared/js/utils/validation'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import { dateToUnixTimestamp } from '@hypefactors/shared/js/utils'
import { isAfter } from 'date-fns'

/**
 * @class StoryForm
 * @extends Form
 * @extends HF_Story
 * @property {HF_CategoryPath[]} categories
 * @property {?string} newsroom_id
 */
export class StoryForm extends Form {
  constructor () {
    super({
      id: { value: '' },
      slug: { value: '' },
      type: { value: '' },
      cover: { value: croppieDataFactory() },
      cover_caption: { value: '' },
      isPinned: { value: false },
      publishAt: { value: null },
      publishedAt: {
        value: '',
        rules: {
          isValidPublishedAt (value) {
            return (this.story.firstPublishedAt && value)
              ? !isAfter(value, this.story.firstPublishedAt)
              : true
          }
        }
      },
      headline: {
        value: '',
        rules: {
          required,
          requiredExcludingSpecialChars,
          minLengthValidCharacters: sanitizeStringForValidator(minLength(3)),
          maxLength: maxLength(255)
        }
      },
      subheadline: {
        value: '',
        rules: {
          maxLength: maxLength(255)
        }
      },
      copytext: {
        value: '',
        rules: { required, minLength: minLength(3), maxLength: maxLength(20000) }
      },
      boilerplate: {
        value: '',
        rules: {}
      },
      contact: {
        value: '',
        rules: { required }
      },
      language: {
        value: '',
        rules: {}
      },
      inPRMode: {
        value: false,
        rules: {}
      },
      prDate: {
        value: '',
        rules: {}
      },
      tags: { value: [] },
      contacts: { value: [] },
      files: { value: [] },
      categories: { value: [] },
      newsroom_id: { value: null, rules: {} }
    })
  }

  /**
   * Merges the Story instance into the form
   * @param {HF_Story} model
   */
  mergeModel (model) {
    return this.merge({
      ...model,
      newsroom_id: model.newsroom ? model.newsroom.id : null,
      language: model.language ? model.language.code : '',
      categories: CategoryService.transformForEditing(model.categories)
    })
  }

  /**
   * Prepares the Story Form object to have fields filled from the Newsroom's data
   * @param {Newsroom} newsroom
   * @param {Object} user
   */
  prefilStory (newsroom, user) {
    let contact
    if (newsroom) {
      contact = [newsroom.contact_name, newsroom.contact_email].join(' - ')
      newsroom.contact_role && (contact += ` | ${newsroom.contact_role}`)
    } else {
      contact = `${user.full_name} - ${user.email}`
    }

    return this.merge({
      boilerplate: newsroom ? newsroom.description : '',
      contact,
      categories: newsroom ? CategoryService.transformForEditing(newsroom.categories) : []
    })
  }

  prepareRequest (brandId) {
    return this.setPayloadTransformer((story) => {
      return {
        ...story,
        in_pr_mode: story.inPRMode,
        pr_date: story.prDate,
        is_pinned: story.isPinned,
        // publish_at: toISOString(story.publishAt), // disable as we moved this to the publish endpoint
        published_at: dateToUnixTimestamp(story.publishedAt),
        files: story.files.map(f => f.id),
        tags: story.tags.map(t => t.title),
        contacts: story.contacts.map(c => c.id),
        categories: CategoryService.transformForSaving(story.categories),
        brand: brandId
      }
    })
  }
}
