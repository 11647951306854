<template>
  <el-dialog
    :visible.sync="isVisible"
    :title="$t('components.story_edit_share_on_social_dialog.title')"
    class="StoryEditShareOnSocialDialog is-fullscreen-mobile"
    width="600px"
    data-testid="StoryEditShareOnSocialDialog"
  >
    <div>{{ $t('components.story_edit_share_on_social_dialog.content') }}</div>
    <social-sharing
      :url="url"
      :title="title"
      :description="description"
      hashtags="press_release"
      inline-template
    >
      <div class="is-flex is-aligned-spaced m-v-xl">
        <network network="facebook" class="is-flex is-column is-aligned-middle">
          <v-button class="is-round is-facebook is-xlarge has-shadow">
            <span class="icon">
              <v-icon type="facebook" />
            </span>
          </v-button>

          <v-button class="is-primary m-t-m">
            {{ $t('general.share') }}
          </v-button>
        </network>

        <network network="twitter" class="is-flex is-column is-aligned-middle">
          <v-button class="is-round is-twitter is-xlarge has-shadow">
            <span class="icon">
              <v-icon type="twitter" />
            </span>
          </v-button>

          <v-button class="is-primary m-t-m">
            {{ $t('general.share') }}
          </v-button>
        </network>

        <network network="linkedin" class="is-flex is-column is-aligned-middle">
          <v-button class="is-round is-linkedin is-xlarge has-shadow">
            <span class="icon">
              <v-icon type="linkedin" />
            </span>
          </v-button>

          <v-button class="is-primary m-t-m">
            {{ $t('general.share') }}
          </v-button>
        </network>
      </div>
    </social-sharing>
    <v-button
      slot="footer"
      class="is-outlined is-primary"
      @click="isVisible = false"
    >
      {{ $t('forms.close') }}
    </v-button>
  </el-dialog>
</template>

<script>
import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'

/**
 * @module StoryEditShareOnSocialDialog
 */
export default {
  name: 'StoryEditShareOnSocialDialog',

  props: {
    visible: {
      type: Boolean,
      required: true
    },
    /** @type HF_Story */
    story: {
      type: Object,
      required: true
    }
  },

  computed: {
    isVisible: generateComputedSetterWithEmit('visible', 'update:visible'),
    url () {
      return this.$getUrl(`${this.story.newsroomSlug}/${this.story.slug}`, 'hypenews', false)
    },
    title () {
      return this.story.headline
    },
    description () {
      return this.story.excerpt
    }
  }
}
</script>
