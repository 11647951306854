<template>
  <div v-loading="isLoading" class="contact-card card contact-card--is-edited">
    <div class="card-content has-text-centered">
      <croppie-wrapper
        ref="croppie"
        v-model="localContact.image"
        :settings="croppieSettings"
        :crop-options="croppieSettings.cropOptions"
        is-circle
        text-size="6"
        entity="ContactsImage"
      >
        {{ $t('components.story_edit.contact_list_item_editable.upload_profile_image') }}
      </croppie-wrapper>
    </div>
    <div class="card-content">
      <form @submit.prevent="saveContact">
        <form-field :validator="$v.localContact.name" :attribute="$t('general.fullname')">
          <input
            v-model="localContact.name"
            :placeholder="$t('general.fullname')"
            class="input"
            type="text"
            name="name"
            @input="$v.localContact.name.$touch()"
          >
        </form-field>
        <form-field>
          <input
            v-model="localContact.job_description"
            :placeholder="$t('general.job_description')"
            class="input"
            type="text"
            name="job_description"
          >
        </form-field>
        <form-field :validator="$v.localContact.email" :attribute="$t('forms.contact_email')">
          <input
            v-model="localContact.email"
            :placeholder="$t('forms.contact_email')"
            class="input"
            type="email"
            name="email"
            @input="$v.localContact.email.$touch()"
          >
        </form-field>
        <form-field :validator="$v.localContact.phone" :attribute="$t('general.phone_number')">
          <input
            v-model="localContact.phone"
            :placeholder="$t('general.phone_number')"
            class="input"
            type="text"
            name="phone"
          >
        </form-field>
      </form>
    </div>
    <div class="card-footer contact-card__footer">
      <div class="buttons">
        <v-button
          :disabled="isLoading"
          class="is-danger is-plain"
          data-testid="cancelContact"
          @click="cancelEdit"
        >
          {{ $t('forms.cancel') }}
        </v-button>
        <v-button
          :disabled="isLoading"
          class="is-dark"
          data-testid="saveContact"
          @click="saveContact"
        >
          {{ $t('forms.save') }}
        </v-button>
      </div>
    </div>
  </div>
</template>
<script>
import Form from '@/services/forms/Form'
import { contactDataFactory } from '@/factories/contacts'
import { required, email } from 'vuelidate/lib/validators'
import { croppieDataFactory, croppieDefaultSettings, mergeCroppieData } from '@hypefactors/shared/js/factories/croppie'
import { StoryApiService } from '@hypefactors/shared/js/services/api/StoryApiService'

const createForm = () => new Form({
  id: { value: '' },
  name: { value: '', rules: { required } },
  job_description: { value: '' },
  email: { value: '', rules: { required, email } },
  brand_id: { value: '' },
  phone: { value: '' },
  clone: { value: false },
  brand: { value: '' },
  image: { value: croppieDataFactory() }
})

export default {
  model: {
    prop: 'contact',
    event: 'change' // we dont use it for now
  },

  props: {
    contact: {
      type: Object,
      default () {
        return { ...contactDataFactory(), image: croppieDataFactory() }
      }
    }
  },

  data () {
    return {
      localContact: createForm(),
      isLoading: false,
      coverImageData: croppieDataFactory(),
      croppieSettings: croppieDefaultSettings(130, 130)
    }
  },

  validations () {
    return {
      localContact: this.localContact.rules()
    }
  },

  computed: {
    isNewContact () {
      return !this.localContact.id
    }
  },

  mounted () {
    this.localContact.merge({
      ...this.contact,
      ...{ image: mergeCroppieData(this.contact.image) }
    })

    !this.localContact.brand && (this.localContact.brand = this.activeBrandId)
  },
  methods: {
    cancelEdit () {
      this.$emit('cancel')
      this.localContact.reset()
    },

    saveContact () {
      this.$v.localContact.$touch()
      if (this.$v.localContact.$error) return
      this.isLoading = true
      this.uploadImage()
        .then(this.saveContactRequest)
        .then(this.successfullySaved)
        .catch(e => {
          this.isLoading = false
          this.$displayRequestError(e, this.$t('errors.cannot_save_contact'))
        })
    },
    uploadImage () {
      return this.$refs.croppie.uploadImage()
    },
    saveContactRequest () {
      this.localContact.payloadTransformer = (data) => {
        delete data.clone
        return data
      }
      if (this.isNewContact) {
        return this.localContact.submit(StoryApiService.createContact)
      } else {
        return this.localContact.submit(payload => StoryApiService.updateContact(this.localContact.id, payload))
      }
    },
    successfullySaved (contactResponse) {
      this.isLoading = false
      this.$notify.success(this.$t('success.contact_saved'))
      this.$emit('save', contactResponse)
    }
  }
}
</script>
