<template>
  <div
    class="StoryEditAdminBar has-background-dotted p-a-s has-shadow"
    data-testid="StoryEditAdminBar"
  >
    <div class="StoryEditAdminBar__navList">
      <story-edit-mode-switch v-model="computedPRMode" class="m-v-s" />

      <div
        v-show="!prMode"
        class="StoryEditAdminBar__navItem is-hidden-mobile"
      >
        <story-edit-status
          :story="story"
          data-testid="storyStatusIndicator"
        />
      </div>
      <!-- DELETE STORY -->
      <dashboard-filters-v2-item class="m-l-a">
        <story-edit-admin-bar-delete-story
          :story="story"
          :is-new-story="isNewStory"
          @delete="$emit('delete')"
        />
      </dashboard-filters-v2-item>
      <!-- PIN STORY -->
      <story-edit-pinned
        v-if="!prMode"
        v-model="form.isPinned"
        data-testid="StoryEditDisplayTypeTrigger"
      />
      <!-- PR Template -->
      <story-edit-pr-style-template-picker
        v-if="prMode"
        v-show="false"
        v-model="prStylesForm.pr_style_id"
        data-testid="StoryEditPRStyleTemplatePickerTrigger"
      />

      <dashboard-filters-v2-item v-if="prMode">
        <story-edit-pr-preview-button
          :has-unsaved-changes="hasChanges"
          :is-new-story="isNewStory"
          data-testid="previewButton"
        />
      </dashboard-filters-v2-item>
      <!-- SAVE CHANGES -->
      <dashboard-filters-v2-item>
        <story-edit-admin-bar-save-story
          :is-new-story="isNewStory"
          :is-loading="isSaving"
          :has-changes="hasChanges"
          data-testid="saveStoryButton"
          @save="$emit('save')"
        />
      </dashboard-filters-v2-item>
      <!-- Publish Story -->
      <div
        v-if="!prMode"
        class="StoryEditAdminBar__navItem"
      >
        <story-edit-admin-bar-publish-button
          :is-new-story="isNewStory"
          :is-publishing="isPublishing"
          :form="form"
          :story="story"
          @save="$emit('save')"
          @publish="$emit('publish')"
          @unpublish="$emit('unpublish')"
        />
      </div>
      <!-- Send to Journalists -->
      <dashboard-filters-v2-item v-if="prMode">
        <story-send-to-journalists-button
          :story-id="story.id"
          :story-updated-at="story.updatedAt"
          :is-new-story="isNewStory"
          :has-files="story.filesCount > 0"
          :has-unsaved-changes="hasChanges"
        />
      </dashboard-filters-v2-item>
    </div>
  </div>
</template>

<script>
import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'

import StoryEditPinned from '@/components/stories/StoryEdit/StoryEditPinned'
import StoryEditStatus from '@/components/stories/StoryEdit/StoryEditStatus'
import StoryEditModeSwitch from '@/components/stories/StoryEdit/StoryEditModeSwitch'
import StoryEditPrPreviewButton from '@/components/stories/StoryEdit/StoryEditPrPreviewButton'
import StoryEditAdminBarSaveStory from '@/components/stories/StoryEdit/StoryEditAdminBarSaveStory'
import StoryEditAdminBarDeleteStory from '@/components/stories/StoryEdit/StoryEditAdminBarDeleteStory'
import StorySendToJournalistsButton from '@/components/stories/StoryEdit/StorySendToJournalistsButton'
import StoryEditAdminBarPublishButton from '@/components/stories/StoryEdit/StoryEditAdminBarPublishButton'
import StoryEditPrStyleTemplatePicker from '@/components/stories/StoryEdit/StoryEditPrStyleTemplatePicker'

/**
 * Renders the admin bar for the {@see module:StoryEditV2}
 * @module StoryEditAdminBar
 */
export default {
  name: 'StoryEditAdminBar',
  components: {
    StoryEditStatus,
    StoryEditPinned,
    StoryEditModeSwitch,
    StoryEditPrPreviewButton,
    StoryEditAdminBarSaveStory,
    StorySendToJournalistsButton,
    StoryEditAdminBarDeleteStory,
    StoryEditAdminBarPublishButton,
    StoryEditPrStyleTemplatePicker
  },

  props: {
    /**
     * The Story object.
     * @type HF_Story
     */
    story: {
      type: Object,
      required: true
    },
    /**
     * @type {StoryForm}
     */
    form: {
      type: Object,
      required: true
    },
    /**
     * Set on the StoryEdit page while publishing is in progress
     */
    isPublishing: {
      type: Boolean,
      default: false
    },
    /**
     * Set on the StoryEdit page when saving is in progress
     */
    isSaving: {
      type: Boolean,
      default: false
    },
    /**
     * Whether the story is in PR Mode
     */
    prMode: {
      type: Boolean,
      default: false
    },
    /**
     * Carries the PRStyle overrides
     * @type StoryPRStylesOverridesForm
     */
    prStylesForm: {
      type: Object,
      default: null
    }
  },

  computed: {
    hasChanges () {
      return this.form.hasChanges() || this.prStylesForm.hasChanges()
    },
    isNewStory () {
      return !this.form.id
    },
    computedPRMode: generateComputedSetterWithEmit('prMode', 'update:prMode')
  }
}
</script>

<style lang='scss'>
@import '~utils';

.StoryEditAdminBar {
  &__navList {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__navItem {
    margin: 0 $margin-small;

    &:last-child {
      margin-right: 0;
    }
  }

  &__dropdown {
    padding: 0;
    // remove all unnecessary borders
    .el-collapse, .el-collapse-item {
      border: none;
    }

    .el-collapse-item:last-of-type {
      .el-collapse-item__wrap, .el-collapse-item__header {
        border-bottom: none;
      }
    }

    .el-collapse-item__content {
      padding-bottom: $padding-small;
    }
  }

  &__navDropdownItem {
    border-bottom: 1px solid $grey-lighter;
    padding: $padding-small $padding;

    &:last-of-type {
      border-bottom: none;
    }

    .el-collapse-item__wrap {
      padding-top: $padding;
    }

    .el-collapse-item__header, .el-collapse-item__arrow {
      height: 28px;
      line-height: 28px;
    }

    .el-radio__input.is-checked + .el-radio__label {
      color: inherit;
    }
  }
}
</style>
