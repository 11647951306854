<template>
  <div class="StoryTagsList">
    <div
      v-for="tag in tags"
      :key="tag.id"
      :class="tagClass"
    >
      <i class="hf hf-tag hf-fw" /> {{ tag.title }}
    </div>
  </div>
</template>

<script>
/**
 * @module StoryTagsList
 */
export default {
  name: 'StoryTagsList',

  props: {
    tags: {
      type: Array,
      default: () => ([])
    },
    tagClass: {
      type: String,
      default: ''
    }
  }
}
</script>
