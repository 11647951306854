<template>
  <el-dialog
    :title="$t('components.story_edit_validation_error_dialog.title')"
    :visible.sync="isVisible"
    width="80%"
    class="StoryEditValidationErrorDialog is-fullscreen-mobile"
    data-testid="StoryEditValidationErrorDialog"
  >
    <div class="is-size-6">
      <div>
        {{ $t('components.story_edit_validation_error_dialog.body') }}:
      </div>
      <base-multi-error :validator="validator" class="m-t-m p-l-m" />
    </div>
    <div slot="footer">
      <v-button
        class="is-primary"
        @click="isVisible = false"
      >
        {{ $t('general.got_it') }}
      </v-button>
    </div>
  </el-dialog>
</template>

<script>
import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'

/**
 * Renders a modal that is open only when user tries to save a story without required data
 * @module StoryEditValidationErrorDialog
 */
export default {
  name: 'StoryEditValidationErrorDialog',

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    validator: {
      type: Object,
      required: true
    }
  },

  computed: {
    isVisible: generateComputedSetterWithEmit('visible', 'update:visible')
  }
}
</script>
