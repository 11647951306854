<template>
  <div class="ContactListAddNew is-width-full">
    <contact-list-card-placeholder
      v-if="addNewContactOverlayVisible"
      class="is-cursor-pointer"
      @click.native="handlePlaceholderClick"
    />
    <template v-else>
      <div
        v-if="!showCreateNewContact"
        key="pickContact"
        class="p-a-m card is-full-height"
        data-testid="ContactListAddNew__SelectNewCardChoices"
      >
        <div class="m-b-m m-t-xl has-text-weight-semibold">
          {{ $t('components.story_edit.contact_list.load_contact') }}
        </div>
        <div class="control">
          <el-select
            v-model="currentlySelected"
            :placeholder="$t('components.story_edit.contact_list.select_contact')"
            filterable
            class="ContactListAddNew__Dropdown"
            popper-class="ContactListAddNew__DropdownDialog"
          >
            <el-option
              v-for="item in unusedContacts"
              :key="item.id"
              :label="`${item.name} - ${item.job_description}`"
              :value="item"
            />
          </el-select>
        </div>
        <div class="has-text-centered">
          <v-button
            class="is-plain is-primary m-t-m"
            data-testid="addNewContact"
            @click="$emit('update:showCreateNewContact', true)"
          >
            + {{ $t('components.story_edit.contact_list.add_empty_card') }}
          </v-button>
        </div>
      </div>
      <contact-list-item-editable
        v-else
        key="createContact"
        data-testid="ContactListAddNew__NewContactCard"
        @save="$emit('save', $event)"
        @cancel="$emit('cancel')"
      />
    </template>
  </div>
</template>

<script>
import { getUniqueArrayValues } from '@/utils'

import ContactListItemEditable from '@/components/stories/ContactListItemEditable'
import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'
import ContactListCardPlaceholder from '@/components/stories/ContactListCardPlaceholder'

/**
 * @module ContactListAddNew
 */
export default {
  name: 'ContactListAddNew',

  components: { ContactListCardPlaceholder, ContactListItemEditable },

  props: {
    /** @type HF_Contact[] */
    allContacts: {
      type: Array,
      required: true
    },
    /** @type HF_Contact[] */
    storyContacts: {
      type: Array,
      required: true
    },
    showCreateNewContact: {
      type: Boolean,
      default: false
    },
    /** @type HF_Contact */
    selectedContactFromList: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      addNewContactOverlayVisible: true
    }
  },

  computed: {
    unusedContacts () {
      return getUniqueArrayValues(this.allContacts, this.storyContacts, 'id')
    },
    currentlySelected: generateComputedSetterWithEmit(
      'selectedContactFromList',
      'selectionChange'
    )
  },

  methods: {
    handlePlaceholderClick () {
      this.addNewContactOverlayVisible = false
      if (!this.allContacts.length) {
        this.$emit('update:showCreateNewContact', true)
      }
    }
  }
}
</script>
