import { HF_CUSTOM_STYLE_FONT_FAMILIES, HF_CUSTOM_FONT_SIZES } from '@hypefactors/shared/js/constants/fonts'
import { Trans } from '@/services/TranslationService'

export const PREditorService = {
  registerFroalaPRFontSizeButton (changeValueCallback, getFieldValueCallback) {
    // transform font size array to one compatible with Froala
    const options = HF_CUSTOM_FONT_SIZES().reduce((all, current) => {
      all[current] = current
      return all
    }, {})
    /* eslint-disable no-undef */
    $.FroalaEditor.DefineIcon('HF_PR_fontSize', { NAME: 'text-height' })
    $.FroalaEditor.RegisterCommand('HF_PR_fontSize', {
      title: Trans.t('components.story_edit_pr_custom_styles.froala_add_custom_font_size'),
      type: 'dropdown',
      focus: false,
      undo: false,
      refreshAfterCallback: false,
      options,
      callback (commandName, commandValue) {
        changeValueCallback(this.el.id, commandValue)
      },
      // Callback on dropdown show.
      refreshOnShow (btn, dropdown) {
        setActiveFroalaDropdownItem(this.el.id, dropdown, getFieldValueCallback)
      }
    })
  },
  registerFroalaPRFontFamilyButton (changeValueCallback, getFieldValueCallback) {
    // transform font family array to one compatible with Froala
    const options = HF_CUSTOM_STYLE_FONT_FAMILIES().reduce((all, current) => {
      all[current.value] = current.label
      return all
    }, {})
    /* eslint-disable no-undef */
    $.FroalaEditor.DefineIcon('HF_PR_fontFamily', { NAME: 'font' })
    $.FroalaEditor.RegisterCommand('HF_PR_fontFamily', {
      title: Trans.t('components.story_edit_pr_custom_styles.froala_add_custom_font_family'),
      type: 'dropdown',
      focus: false,
      undo: false,
      refreshAfterCallback: false,
      options,
      callback (commandName, commandValue) {
        changeValueCallback(this.el.id, commandValue)
      },
      // Callback on dropdown show.
      refreshOnShow (btn, dropdown) {
        setActiveFroalaDropdownItem(this.el.id, dropdown, getFieldValueCallback)
      }
    })
  }
}

/**
 * Finds the currently active custom field and adds a fr-active class to it.
 * Removes the fr-active class from the rest. Only one can be clicked at a time.
 * @param {string} currentFieldId - ID of the field in the dom. Usually same as the field that it is attached to.
 * @param {Object} dropdown - jquery result of the dropdown
 * @param {function} getFieldValueCallback - fetches the value for the current field id.
 */
function setActiveFroalaDropdownItem (currentFieldId, dropdown, getFieldValueCallback) {
  // get the needed value for the current
  const fieldValue = getFieldValueCallback(currentFieldId)
  const anchorQuery = '[data-param1]'
  dropdown.find(fieldValue ? `[data-param1=${fieldValue}]` : anchorQuery)
    .addClass('fr-active')
    .parent().siblings().find(anchorQuery)
    .removeClass('fr-active')
}
