<template>
  <div class="TagsPicker">
    <div class="m-b-m">
      <div v-if="helperText" class="column is-narrow">
        <el-tooltip placement="top">
          <i class="hf hf-question-circle hf-lg" />
          <div slot="content" v-html="helperText" />
        </el-tooltip>
      </div>
      <div class="TagsPicker__list TagsPicker__list--chosen">
        <div class="field is-grouped is-grouped-multiline">
          <div
            v-for="tag in parentTags"
            :key="tag.id"
            class="control"
          >
            <div class="tags has-addons">
              <a class="tag is-dark">{{ tag.title }}</a>
              <a class="tag is-delete" @click="removeTag(tag)" />
            </div>
          </div>
          <input
            v-show="inputVisible"
            ref="saveTagInput"
            v-model="inputValue"
            type="text"
            class="input is-small tag-input"
            placeholder="add tag"
            data-testid="TagsInputField"
            @keyup.enter="handleInputConfirm"
            @keyup.esc="clearInput"
            @blur="handleInputConfirm"
          >
          <button
            v-show="!inputVisible"
            class="button is-small add-new-tag"
            data-testid="NewTagTrigger"
            @click="showInput"
          >
            + {{ $t('components.tags_picker.new_tag') }}
          </button>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div v-if="filteredSuggestedTags.length">
        <div class="subtitle is-6">
          {{ rightLabel || $t('components.tags_picker.suggested_tags') }}
        </div>
        <div
          class="TagsPicker__list TagsPicker__list--suggested tags"
          data-testid="SuggestedTags"
        >
          <a
            v-for="tag in filteredSuggestedTags"
            :key="tag.id"
            class="tag suggested is-dark"
            @click="addTag(tag)"
          >
            {{ tag.title }}
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { getUniqueArrayValues } from '@/utils'
import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'
import uuid from 'lodash/uniqueId'

export default {
  props: {
    suggestions: {
      type: Array,
      default: () => ([])
    },
    value: {
      type: Array,
      required: true
    },
    leftLabel: {
      type: String,
      default: ''
    },
    rightLabel: {
      type: String,
      default: ''
    },
    helperText: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      inputVisible: false,
      inputValue: ''
    }
  },

  computed: {
    parentTags: generateComputedSetterWithEmit(),
    filteredSuggestedTags () {
      return getUniqueArrayValues(this.suggestions, this.value, 'id')
    }
  },

  methods: {
    removeTag (tag) {
      this.parentTags = this.parentTags.filter(t => t.id !== tag.id)
    },

    addTag (tag) {
      if (this.parentTags.find(t => t.title === tag.title)) return
      this.parentTags = this.parentTags.concat(tag)
    },

    showInput () {
      this.inputVisible = true
      this.focus()
    },

    handleInputConfirm () {
      let inputValue = this.inputValue
      if (!inputValue) return
      this.addTag({ id: uuid('tag_'), title: inputValue })
      this.clearInput()
      this.focus()
    },

    focus () {
      this.$nextTick(() => {
        this.$refs.saveTagInput && this.$refs.saveTagInput.focus()
      })
    },

    clearInput () {
      this.inputValue = ''
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

$tagMarginLeft: 10px;
.TagsPicker {
  .tag-input, .add-new-tag {
    border-radius: 4px;
    margin-bottom: 0.75rem;
  }
  .tag-input {
    display: inline-block;
    width: 78px;
    border: 1px solid $grey-lighter;
    box-shadow: none;
    height: 20px;
    margin-bottom: 0.75rem;
  }

  .add-new-tag {
    text-transform: none;
    line-height: 1;
  }

  &__list {
    min-height: 50px;
    display: flex;
    flex-flow: row wrap;
    border: 1px solid $grey-lighter;
    padding: .5rem;
    flex: 1 1 auto;
    align-content: flex-start;
    align-items: flex-start;
    max-width: 100%;
    &--suggested {
      border: none;
    }
  }
}
</style>
