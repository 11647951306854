<template>
  <div class="FileManager">
    <file-manager-filters
      :search.sync="filters.search"
      :type.sync="filters.type"
      :display-type.sync="displayType"
    />

    <div class="FileManager__container">
      <transition-group
        v-if="files.length"
        :class="['FileManager__list--' + displayType]"
        class="Collection FileManager__list"
        tag="div"
        name="list"
      >
        <file-manager-item
          v-for="file in filteredFiles"
          :key="file.id"
          :file="file"
          :is-selected="isSelected(file)"
          :update="updateAction"
          :delete="deleteFile"
          data-testid="FileManagerItem"
          @toggle="toggleFile(file)"
        />

        <div
          v-if="!filteredFiles.length"
          key="no_files-message"
          class="FileManager__list-no-files"
        >
          {{ $t('components.files_picker.no_files_with_selected_filters') }}
        </div>
      </transition-group>

      <div v-else class="FileManager__list-no-files Collection-Item text-muted">
        <p>
          {{ $t('components.files_picker.no_files_uploaded') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueWatcher } from '@hypefactors/shared/js/utils/componentUtilities'
import { debounceMixin } from '@hypefactors/shared/js/mixins/debounceMixin'
import FileManagerFilters from './FileManagerFilters'
import FileManagerItem from './FileManagerItem'

/**
 * @module FileManager
 */
export default {
  name: 'FileManager',

  components: {
    FileManagerFilters,
    FileManagerItem
  },

  mixins: [debounceMixin(['updateSelectedFiles'])],

  props: {
    value: {
      type: Array,
      default: () => ([])
    },
    files: {
      type: Array,
      default: () => ([])
    },
    deleteAction: {
      type: Function,
      default: () => ({})
    },
    updateAction: {
      type: Function,
      default: () => ({})
    }
  },

  data () {
    return {
      displayType: 'grid',
      filters: {
        search: '',
        type: null
      },
      selectedFilesMap: {}
    }
  },

  computed: {
    filteredFiles () {
      const search = this.filters.search || ''
      const type = this.filters.type
      const normalizedSearch = search.toLowerCase().trim()

      let files = this.files

      if (type) {
        files = files.filter(file => file.type === type)
      }

      if (normalizedSearch) {
        files = files.filter(file => file.name.toString().toLowerCase().includes(normalizedSearch))
      }

      return files
    }
  },

  watch: {
    value: {
      immediate: true,
      handler: generateUniqueWatcher('createFilesMap')
    }
  },

  methods: {
    toggleFile (file, shouldSelect = !this.selectedFilesMap[file.id]) {
      if (shouldSelect) {
        this.$set(this.selectedFilesMap, file.id, file)
      } else {
        this.$delete(this.selectedFilesMap, file.id)
      }
      this.updateSelectedFiles()
    },
    createFilesMap (files) {
      this.selectedFilesMap = files.reduce((all, current) => {
        all[current.id] = current
        return all
      }, {})
    },
    async deleteFile (file) {
      if (this.deleteAction) {
        await this.deleteAction(file)
      }
      this.toggleFile(file, false)
    },
    updateSelectedFiles () {
      this.$emit('input', Object.values(this.selectedFilesMap))
    },
    isSelected (file) {
      return !!this.selectedFilesMap[file.id]
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.FileManager {
  &__list {
    display: flex;
    flex-flow: row wrap;
    transition: .3s ease-in;
    position: relative;
  }
  &__container {
    padding: 1rem 0;
  }
}

.FileManager__list--list {
  .FileManagerItem {
    width: 100%;
    flex: 1 1 100%;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    &__checkbox {
      margin-right: 1rem;
    }
    &__download {
      margin-right: 1rem;
    }
    &__delete {
      margin-right: 1rem;
    }
    &__icon {
      margin-right: .5rem;
      width: 50px;
      height: 50px;
    }
    &__content {
      //border-bottom: 1px solid $gray-lighter;
      padding: .8rem 0;
      text-align: left;
    }
  }
}

.FileManager__list--grid {
  .FileManagerItem {
    padding: .5rem;
    &__checkbox {
      position: absolute;
      top: 16px;
      left: 16px;
      z-index: 2;
      display: flex;
    }
    &__download {
      position: absolute;
      top: 10px;
      left: 50px;
      z-index: 2;
    }
    &__delete {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 2;
    }
    &__icon {
      font-size: 2.4rem;
      transition: all 0.2s cubic-bezier(0.38, 0.3, 1, 3);
      min-height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 0;
      padding-bottom: 100%;
      position: relative;
      margin-bottom: 5px;
      > i {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) !important;
      }
    }
    &--selected {
      .FileManagerItem__icon {
        background-color: darken($white, 3%);
        transform: scale(0.95);
      }
    }
  }
}
</style>
